import {getUrlFromRef,uploadAndSave, updateRecord,  useStaging, typesenseApiKey, typesenseHost, uploadAndSave2 } from './firebase.js'
import { Plane,Vector3,Color,Box3, Mesh } from 'three';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { produce } from 'immer';
import { MaterialData, MaterialFiles, MaterialProperties, MeshTypes, ProductInterface, GLBMesh, ProfileInterface, ProfileFirstActions, ObjectStatusType } from '../../../../packages/ts-interfaces/index.js';
import { RepeatWrapping  } from 'three';
import { useLoginState } from "../studioxr/auth/LoginState";

export const materialCategories = [
	"Tile","Stone","Wood", "Fabric","Metal","Concrete", "Leather",
	"Marble - Granite","Terrazzo","Carpet","Paint/Plaster/Ceramic","Organic","Flooring"
].sort()
export const materialColors = ['Red', 'Blue', 'Black', 'Pink', 'Yellow', 'Gray', 'Brown', 'Green' ].sort()

export const materialObjectType = {
    Fabric:'rxm7u7h7o2',
//    Tile:'hardEdgeRectangleThin100x200_tjsfm8k8eu',
    Tile:'hardEdgeSquare125x125_npwvc0jamq',
    Stone:'hardEdgeSquareThin200x200_hzj9ywedam',
	Flooring:'hardEdgeRectangle70x175_d72yi2nili',
	Organic:'Object-000063',
	Terrazzo:'hardEdgeSquareThin200x200_hzj9ywedam',
	Wallpaper:'Object-000169',	
    Wood:'hardEdgeSquareThin200x200_hzj9ywedam',
    Leather:'cutEdge180x18_x4jmb9xgi7',
    Metal:'hardEdgeSquareThin200x200_hzj9ywedam',
    Concrete:'hardEdgeRectangleThin100x200_tjsfm8k8eu',
    Carpet:'Object-000159', 
    Plastic:'softEdgeTile100x200_h5qlejgxz6',
    Other:'hardEdgeRectangleThin100x200_tjsfm8k8eu',
    Worktop:'hardEdgeRectangle120x300_ocipv8x5um',
    Glass:'hardEdgeSquareThin200x200_hzj9ywedam',
    Paint:'paintBlob_hegyb7rce7',
    PaintProd: 'paintBlob_40yq9ndgad',
    'Paint/Plaster/Ceramic':'hardEdgeSquareThin200x200_hzj9ywedam',
    'Marble - Granite':'hardEdgeRectangleThin100x200_tjsfm8k8eu',
    'Wall Mural':'hardEdgeRectangleThin100x200_tjsfm8k8eu',
}


export function filterMesh(data, whitelist=['files', 'name','scale','rotation','materialData']) {
  return whitelist.reduce(
    (result, key) =>
      data[key] !== undefined
        ? Object.assign(result, { [key]: data[key] })
        : result,
    {}
  );
}



export const capitalize = (s) => { if (typeof s !== 'string') return ''; return s.charAt(0).toUpperCase() + s.slice(1); }

export const textureTypes=['alpha','ambient','arm','color','displacement','normal','roughness','metallic']
export const textureSizes=['cent','tiny','small','medium','large','original']

export function generateMaterialFies(color_url) {
	const x = {}
	textureTypes.forEach(type=>{
		textureSizes.forEach(size=>{
			x[type+'_'+size] = color_url
		})
	})
	const files:MaterialFiles = {...x} as MaterialFiles
}


export const getBlobFromURL = (url) => {
    return new Promise((resolve,reject) => {
        if (url instanceof Blob == true || url instanceof File ==true) { resolve(url as Blob) }
        fetch(url).then( (response) => {
            response.blob().then( (blob) => {
                resolve(blob as Blob)
            })
        })
    })
}

// 'height=120,format=auto'
export const cloudflareCDN2 = (url,format) => { return 'https://mattoboard.uk/cdn-cgi/image/'+format+'/'+url; }

export const cloudflareCDN = (url,format) => { 
    if (url?.startsWith('https://api.mattoboard.uk/?image='))  {
        try { const x= new URL(url).searchParams.get('image'); if (x!=null) url=x;  }
        catch { /* empty */ }
    }
    if (url?.startsWith('http://localhost')) return url
    if (url?.startsWith('meshes') || url?.startsWith('gltfs')) return getUrlFromRef(url)
    const s = format.split(',')    
    let i = 'image=' + encodeURI(url);
    s.forEach(x=>{ i += '&' + x?.trim() })
    const u = 'https://api.mattoboard.uk?' + i
    return u
}


export const parseSearch = (searchString='') => {
	const searchObject = {};
	const queries = searchString.replace(/^\?/, '').split('&');
	for (let i = 0; i < queries.length; i++) {
		const split = queries[i].split('=');
		searchObject[split[0]] = split[1];
	}
	return searchObject;
}

export async function updateProfile(profile:ProfileInterface,pic={}, firebaseRef) {
    return new Promise((resolve, reject) => {
        if (!profile.id) throw new Error("Profile ID is required")
        if (profile.uid?.length < 4) throw new Error("Profile UID is required")
        if (profile.email?.length < 4) throw new Error("Profile Email is required")
        const updatedProfile = produce(profile, (draft:ProfileInterface) => {
            draft.updated_at = new Date()
        })
        const unfrozenProfile = JSON.parse(JSON.stringify(updatedProfile))
        //unfortunate hack here.  uploadAndSave cannot support frozen data structures... better to do the fix here than there 
        if (unfrozenProfile.createdAt && updatedProfile.createdAt) unfrozenProfile['createdAt'] = updatedProfile.createdAt
        if (unfrozenProfile.updated_at && updatedProfile.updated_at) unfrozenProfile['updatedAt'] = updatedProfile.updated_at
        if (unfrozenProfile.created_at && updatedProfile.created_at) unfrozenProfile['created_at'] = updatedProfile.created_at

        uploadAndSave2(firebaseRef,unfrozenProfile.id, unfrozenProfile.uid, unfrozenProfile, pic)
            .then(result=>{
            resolve(unfrozenProfile);
            })
            .catch(error=> { reject(error); console.log("error ", error); })
        })	
  }



export const randomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;


export const camelize = (text='') => {
	    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
	    return text.substr(0, 1).toLowerCase() + text.substr(1);
}
export const createCanvas = (w,h) => { const i:any=document.createElement("canvas"); i.height=h;i.width=w; i.ctx=i.getContext('2d');return i; }
export const generateID = () =>  { return Array.from(Array(10), () => Math.floor(Math.random() * 36).toString(36)).join(''); }

export const scaleAndCenter = (canvas,overlayImage) => {
	return new Promise((resolve, reject) => {	
		getCanvas(overlayImage).then( (overlayCanvas) => {
			drawImageProp(canvas.ctx, overlayCanvas)
			resolve(canvas)
		})
	})
}

export const getContactForm = (profile) => {
	if (profile?.email?.length > 2) {
		const email = profile.email || '' 
		const fname = profile.first_name || ''
		const lname = profile.last_name || ''
		const name = fname + ' ' + lname
		return `https://docs.google.com/forms/d/e/1FAIpQLSfWFoQ5pcpzU9ZGMsYYpzL6u7hOOZ5127_NZb__oz8TuMpSCw/viewform?usp=pp_url&entry.69172660=${email}&entry.923666355=${name}`		
	}
	else {
		return 'https://docs.google.com/forms/d/e/1FAIpQLSfWFoQ5pcpzU9ZGMsYYpzL6u7hOOZ5127_NZb__oz8TuMpSCw/viewform'
	}
}

/*
Sadly, got some ugly data structures here.  Let's clean it up as we move through the pipeline.
*/

const gltfMeshKeys=['category','id','name','isStaticObject','meshTextureRepeat',
'rotation','scale','objectStatus','version','files','mesh','url','physicalObject','definedObject','type']
export const cleanGLTFMesh = (gltfRawData={}) => {
    const gltfMesh = {}
    if (!gltfRawData) return gltfMesh
    Object.keys(gltfRawData).forEach(key=>{
        if(gltfMeshKeys.includes(key)){
            gltfMesh[key] = gltfRawData[key]
        }
    })
    return gltfMesh
}

export const defaultMaterialProperties={
    textureRepeat: {value:1.0, min:0.001, max:10.0, step:0.001},
    envMapIntensity : {value:1.0, min:0, max:2, step:0.01},
    clearcoat: {value:0.0, min:0, max:1, step:0.01},
    clearcoatRoughness: {value:0.0, min:0, max:1, step:0.01},
    ior : {value:1.5, min:1.0, max:2.333, step:0.01},
    reflectivity: {value:0.5, min:0, max:1, step:0.01},
    aoMapIntensity: {value:1.0, min:0, max:2, step:0.01},
    displacementScale : {value:0.0, min:-1, max:1, step:0.001},
    displacementBias  : {value:0, min:-10, max:10, step:0.01},
    emissiveIntensity: {value:1.0, min:0, max:5, step:0.01},
    metalness: {value:0.0, min:0, max:1, step:0.01},
    roughness: {value:1.0, min:0, max:1, step:0.01},
    transmission: {value:0.0, min:0, max:1, step:0.01},
    thickness:{value:0.0,min:0,max:5,step:0.01},    
    normalScale: { value:1.0, min:0, max:5,step:0.1},
    sheen: {value:0.0, min:0, max:1, step:0.01},
    sheenRoughness: {value:1.0, min:0, max:1, step:0.01},
}

//656565. 16777215
//808080
//new Color(0xFFFFFF)
export const materialDefaults = {clearcoat:0,clearcoatRoughness:0,
	ior:1.5,reflectivity:0.5,transmission:0,aoMapIntensity:1,
	displacementScale:1,displacementBias:0,emissiveIntensity:1.0,envMapIntensity:1.0,
	metalness:0.1,refractionRatio:0.98,roughness:0.6,side:0, transparent:false,
	color:new Color(0xFFFFFF), emissive:new Color(0x000000), sheenColor:new Color(0xFFFFFF), }

export const materialDefaults2 = {
    clearcoat:0,clearcoatRoughness:0,
    ior:1.5,reflectivity:0.5,transmission:0,aoMapIntensity:1,
    displacementScale:1,displacementBias:0,emissiveIntensity:1.0,envMapIntensity:1.0,
    metalness:0.0,refractionRatio:0.98,roughness:1.0,side:0, transparent:false,
    color:new Color(0xFFFFFF), emissive:new Color(0x000000), sheenColor:new Color(0xFFFFFF),
}

//export const materialDefaults = {side:2}

export const getDimensions = (physicalMeshKey,scene=window.scene) => {
    const adjust = 11

    const mesh = window.scene?.children?.find( c => c.userData?.physicalMesh==true && c.userData?.key == physicalMeshKey )
    if (!mesh) return {x:0,y:0,z:0}
    const box = new Box3()
    box.setFromObject(mesh)
    const x  = (box.max.x - box.min.x)*adjust; 
    const y = (box.max.y - box.min.y)*adjust; 
    const z = (box.max.z - box.min.z)*adjust;    
    return {x,y,z}
}
export const dimensionsToString = (dim,precision=2) => { 
    return `${dim?.x.toFixed(precision)} x ${dim?.y.toFixed(precision)} x ${dim?.z.toFixed(precision)}mm` 
}

export const dimensionsToStringFlipped = (dim,precision=2) => { 
    return `${dim?.x.toFixed(precision)} x ${dim?.z.toFixed(precision)} x ${dim?.y.toFixed(precision)}mm` 
}

export const calculateRepeatModifier = (physicalObject:PhysicalObjectInterface,scene=window.scene) => { 
    if (!physicalObject?.originalScale || !physicalObject?.key) return 1;
    const threeJSObject = scene.children.filter( (child) => child.userData.key == physicalObject.key)[0]
    if (!threeJSObject?.scale) return 1;
    const l = ((physicalObject.scale[0] / physicalObject.originalScale) * (physicalObject.meshTextureRepeat ?? 1) )
    return l
}

export const convertGLBMeshToPhysicalObject = (mesh:GLBMesh,meshUrl,defaultColor=0xC5C5C5) => {
    if (!mesh) return;
    const scale = Array.isArray(mesh.scale) ? mesh.scale : [mesh.scale,mesh.scale,mesh.scale]
    const newPhysicalObject:PhysicalObjectInterface = {
        scale: scale,
        rotation: mesh.rotation ?? [0, 0, 0],
        position: [0, 0, 0],
        url: meshUrl ?? getUrlFromRef(mesh.web_sized_glb),
        key: Math.random(),
        name: 'Mesh',
        mergeGeo:false,
        version:mesh.version ?? 2,
        type: mesh?.meshType=='not_static' ? 'gltf' : mesh.meshType,
        isStaticObject: mesh.meshType=='static' || mesh.meshType=='gltf',
        meshTextureRepeat: mesh.meshTextureRepeat ?? 1
    }    
    if (newPhysicalObject.isStaticObject===false) {
        if (!newPhysicalObject.materialData) {
            const materialProps:MaterialProperties = deepClone(materialDefaults)      
            materialProps.metalness=0
            materialProps.roughness=1.0
            materialProps.color = (new Color(defaultColor) as any).toJSON();        
            newPhysicalObject.materialData = {materialProps: materialProps} as MaterialData
        }
    }
    return newPhysicalObject
}

export function getTimeAgo(time:number) {
    const diff = Date.now() - time
    const minutes = Math.floor(diff / 60000)
    if (minutes < 60) return minutes + ' minutes ago'
    const hours = Math.floor(minutes / 60)
    if (hours < 24) return hours + ' hours ago'
    const days = Math.floor(hours / 24)
    return days + ' days ago'
}

export const createHash = (s) =>  s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              

export const createNewGLBFile = (mesh=MeshTypes.GLTF) => {
    return {
        meshType:mesh,meshTextureRepeat:1,isStaticObject:mesh!=MeshTypes.NOT_STATIC,
        rotation:[0,0,0],scale:[1,1,1],version:2,
        full_sized_glb:null as any,web_sized_glb:null as any,full_sized_vertices:0,
        web_sized_vertices:0,  full_file_size:0,web_file_size:0,
        rendered_image:null as any,
        thumbnail:null as any,
    }
}

/*
A few ugly things to move a stored gltf object to a physical object.
*/
export const convertGLTFMeshToPhyscialObject = (mesh:ProductInterface,sceneScaleFactor=1, gltfSize='web_sized_glb', defaultColor=0xC5C5C5) => {
    if (!mesh) return;
    // if (mesh.physicalObject) return mesh.physicalObject;
//    const scale = (Array.isArray(mesh.scale) ? mesh.scale : [mesh.scale,mesh.scale,mesh.scale]).map(x => x * sceneScaleFactor)
    const scale = Array.isArray(mesh.mesh?.scale) ? mesh.mesh?.scale : [mesh.mesh?.scale,mesh.mesh?.scale,mesh.mesh?.scale]
    const gltfMesh = produce(mesh, (draft:any)=> {
        delete draft.triangles;  delete draft.thumbnailSize; delete draft.id;
    })
    const newPhysicalObject:PhysicalObjectInterface | any= {
        ...gltfMesh,     
        ...{type: gltfMesh.mesh?.web_sized_glb ? 'gltf' : 'shape',
            scale:scale,
            rotation:gltfMesh.mesh?.rotation,
            meshTextureRepeat: gltfMesh.mesh?.meshTextureRepeat,
            refId:mesh.id,
            position:[0,0,0],
            url: getUrlFromRef(gltfMesh.mesh?.[gltfSize]),
            key:Math.random()}
    }    
    if (!newPhysicalObject.materialData) {
        const materialProps:MaterialProperties = deepClone(materialDefaults)      
        materialProps.metalness=0
        materialProps.roughness=1.0
        materialProps.color = (new Color(defaultColor) as any).toJSON();        
        newPhysicalObject.materialData = {materialProps: materialProps} as MaterialData
    }
    return newPhysicalObject
}

export const roundBytesToMB = (bytes) => { return  Math.round( (bytes / (1024*1024)) * 100) / 100;}
export const round2 = (n) => { return(Math.round(n * 100)) / 100;}

function drawImageProp(ctx, img, x=0, y=0, w=ctx.canvas.width, h=ctx.canvas.height, offsetX=0.5, offsetY=0.5) {
    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }
    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;
    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;
    let iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,   // new prop. width
        nh = ih * r,   // new prop. height
        cx, cy, cw, ch, ar = 1;
    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;
    cw = iw / (nw / w);
    ch = ih / (nh / h);
    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;
    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
}
const plane = new Plane(new Vector3(0, 1, 0), 0);
export const positionIn3D = (gl,raycaster,camera,event) => {
//	console.log(event.unprojectedPoint.z, ' vs ', event.point.z);
      const rect = gl.domElement.getBoundingClientRect();
      const intersects = new Vector3();
      const mouse:any = {}
      mouse.x  = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y  = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);         
      raycaster.ray.intersectPlane(plane, intersects);
      return intersects
}

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth*ratio, height: srcHeight*ratio };
 }

export async function urlToImage(url) {
    console.log("Trygin to load ", url);
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = "Anonymous"
        img.onload = () => { console.log("I loaded ", url); resolve(img) }
        img.onerror = () => { console.log("I failed ", url); reject("Cannot load image"); }
        img.src = url
    })


}

function getCanvasScale(width, height) {
    const maxWidth = 8192;
    const maxHeight = 8192;
    let scale = 1;
    if (width > maxWidth || height > maxHeight) {
        scale = Math.min(maxWidth / width, maxHeight / height);
    }
    return scale;
}


export async function urlToCanvasWithoutCORS(url) {
    return new Promise((resolve, reject) => {
        if (!url || url.length  <1) { resolve(null); return; }
        if (url instanceof Blob == true || url instanceof File ==true) { resolve(url as Blob); return; }
        const img = new Image()
        img.crossOrigin = "Anonymous"
        img.onload = () => {
            const scale = getCanvasScale(img.width, img.height)
            const canvas = createCanvas(img.width*scale, img.height*scale)  
            canvas.ctx.drawImage(img, 0, 0, img.width*scale, img.height*scale)
            resolve(canvas)
        }
        img.onerror = (e) => {
            console.log("error loading ", url,e);
            reject("Cannot load image");
        }
        img.src = url    
    })
}

export async function urlToCanvas(url) {
	return new Promise((resolve, reject) => {
            const img = new Image()
            let isCORSImage = false
            img.crossOrigin = "Anonymous"
            img.onload = () => {
                const scale = getCanvasScale(img.width, img.height)
                const canvas = createCanvas(img.width*scale, img.height*scale)  
                canvas.ctx.drawImage(img, 0, 0, img.width*scale, img.height*scale)
                resolve(canvas)
            }
            img.onerror = (err) => {
                if(!isCORSImage) {
                    img.src = getCORSImages(url)
                    isCORSImage = true
                }
                else {
                    alert("Sorry - Cannot process this file.")
                    reject(err);
                }
            }
            img.src = url    
	})
}

function getCORSImages(url) {
    return 'http://res.cloudinary.com/dck6qckup/image/fetch/w_1000/' + url;
    // return 'https://cors-anywhere.herokuapp.com/'+ url;
}

export function deepClone(obj) {  return JSON.parse(JSON.stringify(obj)) }

export async function getCanvas(src)  {
	return new Promise((resolve, reject) => {
		if (src instanceof Image ) { urlToCanvas(src.src).then(canvas=>resolve(canvas)).catch(err=>reject(err)) }
		else if (src instanceof File) {
			const reader = new FileReader();
			reader.onload = () => {
				const dataURL = reader.result;
				urlToCanvas(dataURL).then( (canvas) =>  resolve(canvas) )
			}
			reader.readAsDataURL(src)
		}	
		else if (typeof(src)=='string') {  urlToCanvas(src).then( (canvas) => resolve(canvas)).catch(err=>reject(err)) }
		else if (src.tagName=='CANVAS') { resolve(src)  }
	})
}
	

export function colorTemperatureToRGB(kelvin){
    const temp = kelvin / 100;
    let red, green, blue;
    if( temp <= 66 ){ 
        red = 255; 
        green = temp;
        green = 99.4708025861 * Math.log(green) - 161.1195681661;
        if( temp <= 19){
            blue = 0;
        } else {
            blue = temp-10;
            blue = 138.5177312231 * Math.log(blue) - 305.0447927307;
        }
    } else {
        red = temp - 60;
        red = 329.698727446 * Math.pow(red, -0.1332047592);    
        green = temp - 60;
        green = 288.1221695283 * Math.pow(green, -0.0755148492 );
        blue = 255;
    }
	
	const c ={r : clamp(red,   0, 255),g : clamp(green, 0, 255),b : clamp(blue,  0, 255)}	
	return new Color(c.r/255 ,c.g/255,c.b/255)
	//const h = rgb_to_hex(c)
	//console.log(h);
	//return h
}
function clamp( x, min, max ) {
    if(x<min){ return min; }
    if(x>max){ return max; }
    return x;
}
function rgb_to_hex(rgb){
	return "#"
		+ ("0" + rgb.r.toString(16)).substr(-2)
		+ ("0" + rgb.g.toString(16)).substr(-2)
		+ ("0" + rgb.b.toString(16)).substr(-2)
  }

export async function resizeImage(img,maxWidth=600,maxHeight=600,imgName) {
	return new Promise((resolve, reject) => {
        getCanvas(img).then( (imgCanvas:any) => {
			let ratio = 1;
            let widthRatio = 1, heightRatio = 1;

            if(imgCanvas.width > maxWidth) widthRatio = maxWidth / imgCanvas.width;
			if(imgCanvas.height > maxHeight) heightRatio = maxHeight / imgCanvas.height;

            ratio = widthRatio < heightRatio ? widthRatio : heightRatio;

			const canvasCopy = createCanvas(imgCanvas.width * ratio, imgCanvas.height * ratio);
            canvasCopy.id =imgName? imgName: img.name
			canvasCopy.ctx.drawImage(imgCanvas,0,0,imgCanvas.width,imgCanvas.height,0,0,canvasCopy.width,canvasCopy.height);

            resolve(canvasCopy);

			// const i = new Image()
			// i.onload = () => resolve(i)
			// i.src = canvasCopy.toDataURL(toPath)
		})
        .catch((err) => {
            reject(err);
        })
	})
}
export async function convertImageToBlob(canvas, toPath){
     const extension = toPath.match('image/png')?'.png':'.jpg';
	return new Promise((resolve, reject) => {
        canvas.toBlob(function(blob) {
            blob.extension = extension;
            resolve(blob);
        },toPath)
    })
}
export async function uploadImage(name,img,toPath,uid){
    return new Promise((resolve, reject) => {

        const data:any = {
            name:name,
            uid:uid,
            updatedAt:Date.now(),
            id:camelize(name) + '_' + generateID()}
        
        console.log("Name is ",name);
        convertImageToBlob(img,toPath)
        .then((imageBlob) => {
            uploadAndSave(data.id, 'userImages', data.uid, data, {file:imageBlob})
                .then(result=>{
                    resolve(result);
                })
                .catch(error=> { console.log("error ", error); })
              })
        })	
}
    export const checkFileType = (file) => {
        const fileType = file.type.toLowerCase();
        const isImage = fileType.startsWith('image/')
        const toPath = fileType.match('image/png')?'image/png':'image/jpeg';
        const fileName = file.name.replace(/[&\/\\#,+()$~%'":*?<>{}@!^]/g, '');
        return {isImage:isImage,toPath:toPath,fileName:fileName} 
    }
    export const checkWebsiteFileType = (html) => {
        const imageSrc = new DOMParser().parseFromString(html, "text/html").querySelector('img')?.src
        if(imageSrc?.includes('mattoboard')) return null
        if(imageSrc){
            const isImage = true
            const extension = (imageSrc?.includes("jpeg")|| imageSrc?.includes("jpg"))?'jpeg':imageSrc?.includes("png")?'png':'jpeg';
            const toPath = extension.match('png')?'image/png':'image/jpeg';
            const imageAlt = new DOMParser().parseFromString(html, "text/html").querySelector('img')?.alt
            let fileName = imageAlt ? imageAlt :  imageSrc.split("/")[(imageSrc.split("/")).length -1]
            fileName = fileName?.replace(/[&\/\\#,+()$~%'":*?<>{}@!^]/g, '')+ '.' + extension;
            return {src:imageSrc,isImage:isImage,toPath:toPath,fileName:fileName} 
        }
        return null
    }
    export const hashCode = (s) => {
        return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);              
    }

    export const material50cm =     {"description":"","files":{"large_color":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_large_v2.jpg","color_original":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/1641555717142_color_original","small_color":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_small_v2.jpg","thumbnail":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_cent_v2.jpg","cent_color":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_cent_v2.jpg","medium_color":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_medium_v2.jpg","tiny_color":"materials/0gcVfuJkctTs0p7IRBVOh4WDqYw2/5cm_s9rhqdeid3/color_tiny_v2.jpg"},"active":true,"tags":[],"id":"5cm_s9rhqdeid3","materialCategory":["Carpet"],"supplier":"","updatedAt":1641555717141,"name":"5cm","category":null,"materialProps":{"thickness":0,"metalness":0,"displacementScale":0,"sheenRoughness":1,"envMapIntensity":1,"ior":1.5,"normalScale":[0.4,0.4],"aoMapIntensity":1,"displacementBias":0,"color":"#ffffff","clearcoat":0,"textureRepeat":1,"transmission":0,"wireframe":false,"emissive":"#000000","roughness":1,"sheenTint":"#ffffff","sheen":0,"reflectivity":0.5},"visible":false,"scale":0.06,"isMaterialChangeable":null}

    export const IsUrlShow = () => {
        const url = window.location.href
        if(url.includes('show/')) return true;
        if(url.includes('metasku/')) return true;
        return false;
    }
    export const unflattenObject = (data) => {
        if (Object(data) !== data || Array.isArray(data))
            return data;
        const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
            resultholder = {};
        for (const p in data) {
            let cur = resultholder,
                prop = "",
                m;
            while (m = regex.exec(p)) {
                cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
                prop = m[2] || m[1];
            }
            cur[prop] = data[p];
        }
        return resultholder[""] || resultholder;
    };

    export const getProductDisplayName = (product:ProductInterface) => {
        if (!product) return 'Unknown'
        let displayName = product.name
        if (product.supplier && product.supplier.indexOf('BEHR') >= 0) displayName = product.supplier + ' ' + product.name
        else if (product.name.indexOf(' - ') < 1 && product.supplier) {
            displayName = product.supplier  +'-'+ product.name        
        }
        return displayName
    }

    export const getMaterialThumbnail = (material) => {
        if ( material?.materialData?.renderedImage?.length > 5) {
            //brin back rendered image!
           return cloudflareCDN(material.materialData.renderedImage, 'height=150, width=150,format=auto') 
        }
        else if (material?.materialData?.files?.thumbnail)  { 
            return getUrlFromRef(material.materialData.files.thumbnail,true) 
        }
        else {
            let renderedImage= material?.materialData?.files?.color_original
            if(!renderedImage) renderedImage = material?.materialData?.renderedImage;
            if(renderedImage) return cloudflareCDN(renderedImage, 'height=90, width=90,format=auto') 
            else return false;
        }
	}
    export const getProductThumbnail = (gltf) => {
        if (gltf.mesh?.rendered_image) return cloudflareCDN(gltf.mesh?.rendered_image,'height=90,format=auto')		
        if (gltf.mesh?.thumbnail) return cloudflareCDN(gltf.mesh?.thumbnail,'height=90,format=auto')		
		const thumbnail =  gltf.files.thumbnail
	    return getUrlFromRef(thumbnail)
	}

    export const getMaterialThumbnailFromMaterialData = (materialData) => {
        if (materialData?.files?.thumbnail)  { return getUrlFromRef(materialData.files.thumbnail,true) }
        else {
            let renderedImage= materialData?.files?.color_original
            if(!renderedImage) renderedImage = materialData?.renderedImage;
            if(renderedImage) return cloudflareCDN(renderedImage, 'height=90,format=auto') 
            else return false;
        }
	}


    function isWriteable(obj) {
        try {
            obj.test = 1; delete obj.test;
            return true;
        } 
        catch (e) { return false; }
    }

    function removeColorOriginal(materialData) {
            delete materialData.files.color_original;
        return materialData;
    }

    export const handleMeshChange=(record,getCurrentThreeJSObject,getCurrentSelectedPhysicalObject,setProjectData,updatePhysicalObject,selectedMaterialId=0) => {
        let firebaseRecord = record
        if (!firebaseRecord) return;
        if (firebaseRecord?.materialData.materialProps==null) {
            firebaseRecord.materialData.materialProps = deepClone(materialDefaults)
        }
        if (isWriteable(firebaseRecord.materialData.materialProps)==false) { 
            firebaseRecord = deepClone(firebaseRecord)
        }
        if (!firebaseRecord.materialData?.materialProps?.textureRepeat) {
            firebaseRecord.materialData.materialProps.textureRepeat = 1
        }
        const threeObject = getCurrentThreeJSObject ? getCurrentThreeJSObject() : null
        const selectedPhysicalObject= getCurrentSelectedPhysicalObject()
        
        if (selectedPhysicalObject?.type=='paint' &&  firebaseRecord?.productType != 'paint') return;
        if (firebaseRecord?.productType == 'paint' && selectedPhysicalObject != null &&  selectedPhysicalObject?.type != 'paint') return;

        // if((selectedPhysicalObject?.type == 'paint' &&  firebaseRecord?.productType != 'paint') 
        //     || ( firebaseRecord?.productType == 'paint' && selectedPhysicalObject?.type != 'paint')
        //     )  return;
        const isPaint = selectedPhysicalObject?.type=='paint'
        //background object
        
        if (threeObject==null) {
            //This is a background request.
            const backgroundTexture: ProductInterface = {
                id: firebaseRecord.id,
                name: firebaseRecord.name,
                productType: firebaseRecord.productType,
                objectStatus: firebaseRecord.objectStatus,
                updatedAt: firebaseRecord.updatedAt,
                materialData: firebaseRecord.materialData,
                isStaticObject: false
            }
            if(backgroundTexture.materialData?.materialProps?.textureRepeat) {
                backgroundTexture.materialData.materialProps.textureRepeat = backgroundTexture.materialData.materialProps.textureRepeat + 10
            }
            if(backgroundTexture.objectStatus==ObjectStatusType.USER_CREATED && backgroundTexture.productType=='paint' && backgroundTexture?.materialData?.files?.color_original) {
                backgroundTexture.materialData = removeColorOriginal(structuredClone(firebaseRecord.materialData));
                backgroundTexture.materialData.renderedImage = firebaseRecord.materialData.files.color_original;
            }
            setProjectData(state=> { state.backgroundTexture = backgroundTexture } )
        }
        else if (isPaint) {            
            const updatedObject = produce(selectedPhysicalObject, draft=> {
                if (firebaseRecord.materialData?.paintMaterials?.length > 0) draft.materialData = firebaseRecord.materialData
                else {
                    // //lets get r id of this at some point.
                    if (!draft.paintMaterials) draft.paintMaterials = [];
                    draft.paintMaterials[selectedMaterialId]=firebaseRecord.materialData.materialProps             
                    if (!draft.paintMaterialsData) draft.paintMaterialsData = [];
                    if (!draft.paintMaterialsData[selectedMaterialId]) draft.paintMaterialsData[selectedMaterialId]={preset:firebaseRecord.materialData.preset,id:null,name:null,renderedImage:null};
                    if (firebaseRecord?.materialData?.preset) draft.paintMaterialsData[selectedMaterialId].preset = firebaseRecord.materialData.preset;
                    if (firebaseRecord?.materialData?.id || firebaseRecord?.id) draft.paintMaterialsData[selectedMaterialId].id = firebaseRecord.materialData.id || firebaseRecord.id ;
                    if (firebaseRecord?.materialData?.name || firebaseRecord?.name) draft.paintMaterialsData[selectedMaterialId].name = firebaseRecord.name || firebaseRecord.materialData.name;
                    if (firebaseRecord?.materialData?.renderedImage) draft.paintMaterialsData[selectedMaterialId].renderedImage  = firebaseRecord.materialData.renderedImage;  
                    if (firebaseRecord?.objectStatus) draft.paintMaterialsData[selectedMaterialId].objectStatus = firebaseRecord.objectStatus;
                    if (firebaseRecord?.objectStatus==ObjectStatusType.USER_CREATED) {
                        draft.paintMaterialsData[selectedMaterialId].userCreated  = true;            
                    }
                    else {
                        draft.paintMaterialsData[selectedMaterialId].userCreated  = false;  
                    } 
                }
            })   
            updatePhysicalObject(updatedObject)  
        }
        else {		
            const updatedObject = produce(selectedPhysicalObject, draft=> {
                if (draft.materialData==null) draft.materialData={id: null,name:null,files:null,materialProps:null};
                if (firebaseRecord?.materialData?.id) draft.materialData.id = firebaseRecord.materialData.id;
                if (firebaseRecord?.materialData?.name) draft.materialData.name = firebaseRecord.materialData.name;
                if (firebaseRecord?.uid) draft.materialData.uid = firebaseRecord.uid;
                if (firebaseRecord?.id) draft.materialData.id = firebaseRecord.id;
                if (firebaseRecord?.materialData?.aspectRatio) draft.materialData.aspectRatio = firebaseRecord.materialData.aspectRatio;
                if (firebaseRecord?.materialData?.files) draft.materialData.files = firebaseRecord.materialData.files;
                if (firebaseRecord?.materialData?.materialProps) draft.materialData.materialProps = firebaseRecord.materialData.materialProps;
            })                                    
            updatePhysicalObject(updatedObject)
        }		
    }
    //super ugly piece of code to describe how to repeat a texture across a mesh
    export const threejs_TextureRepeat = (t,textureRepeatModifier,scaledMesh,textureRepeat) => {        
        if (t?.repeat) {
            t.wrapS = RepeatWrapping; t.wrapT = RepeatWrapping;
            textureRepeatModifier = textureRepeatModifier == 0 || isNaN(textureRepeatModifier) ? 1 : textureRepeatModifier	
            t.repeat.x = t.repeat.y =  (textureRepeat ?? 1) * (textureRepeatModifier ?? 1)		
            const ratio = t.source?.data?.width / t.source?.data?.height
            if (isNaN(ratio) || scaledMesh==true) return;
            else if (ratio != 1 ) {
                if (ratio > 1) { t.repeat.y = t.repeat.y * ratio }
                else { t.repeat.x = t.repeat.x / ratio }
            }
        }   
    }


    export interface ComputeMapsInput {
        id:string;
        normalMap: string;
        albedoMap: string;
    }

    export async function computeMaps(img, id: string, currentUserUid: string): Promise<ComputeMapsInput> {        
        const storageURL = useStaging ? 'mattoboard-staging.appspot.com' : 'mattoboard-b8284.appspot.com'
        const normalMapUrl = useStaging ? 'https://us-central1-mattoboard-staging.cloudfunctions.net/generate_maps' : 'https://us-central1-mattoboard-b8284.cloudfunctions.net/generate_maps';
        const response = await fetch(`https://storage.googleapis.com/${storageURL}/userImages/${currentUserUid}/${id}/normal.jpg`)        

        if (response.status==200) {
            const computerMapsInput: ComputeMapsInput = {
                id: id,
                normalMap: `https://storage.googleapis.com/${storageURL}/userImages/${currentUserUid}/${id}/normal.jpg`,
                albedoMap: `https://storage.googleapis.com/${storageURL}/userImages/${currentUserUid}/${id}/albedo.jpg`,
            }
            return computerMapsInput
        }
        const inputData = {
            id: id,
            uid: currentUserUid,
            image_url: img?.src ? img.src : img,
        };
        const resp = await fetch(normalMapUrl, {
            method: 'POST',
            headers: {'Accept': 'application/json','Content-Type': 'application/json'},
            body: JSON.stringify(inputData)
        });
        console.log("look ", inputData);
        const data = await resp.json();
        return data as ComputeMapsInput
    }
    function decimalHexToRGB(decimalHex) {
        const hexString = decimalHex.toString(16).padStart(6, '0');
        const r = parseInt(hexString.substr(0, 2), 16);
        const g = parseInt(hexString.substr(2, 2), 16);
        const b = parseInt(hexString.substr(4, 2), 16);
        return { r, g, b };
      }
      
      function rgbToDecimalHex(rgb) {
        return parseInt(`${rgb.r.toString(16).padStart(2, '0')}${rgb.g.toString(16).padStart(2, '0')}${rgb.b.toString(16).padStart(2, '0')}`, 16);
      }

      const a1 = (v: number) => (v > 10.314724 ? ((v + 14.025) / 269.025) ** 2.4 : v / 3294.6);
      const a2 = (v: number) => (v > 0.0088564 ? v ** (1 / 3) : v / (108 / 841) + 4 / 29);

    function toHCL(r: number, g: number, b: number) {
        const y = a2((r = a1(r)) * 0.222488403 + (g = a1(g)) * 0.716873169 + (b = a1(b)) * 0.06060791);
        const l = 500 * (a2(r * 0.452247074 + g * 0.399439023 + b * 0.148375274) - y);
        const q = 200 * (y - a2(r * 0.016863605 + g * 0.117638439 + b * 0.865350722));
        const h = Math.atan2(q, l) * (180 / Math.PI);
        return [h < 0 ? h + 360 : h, Math.sqrt(l * l + q * q), 116 * y - 16];
    }

    export const getHex = (color) =>  isNaN(color) ? color :  '#' + (color+0x10000).toString(16).substr(-3).toUpperCase();
    export const hexToInt = (color:string) =>  parseInt(color.replace('#',''),16)

    export function generateHCLValues(targetDecimalHex) {
        const targetRGB = decimalHexToRGB(targetDecimalHex);
        const resultHCL = toHCL(targetRGB.r,targetRGB.g,targetRGB.b)
        return [parseFloat(resultHCL[0].toFixed(2)),parseFloat(resultHCL[1].toFixed(2)),parseFloat(resultHCL[2].toFixed(2))];
    }

    export function generateHexCodeArray(targetDecimalHex) {
    const targetRGB = decimalHexToRGB(targetDecimalHex);
    const hexCodeArray: number[] = [];
    const range = 16; // numCodes;
    
    for (let i = -range; i <= range; i++) {
        for (let j = -range; j <= range; j++) {
            for (let k = -range; k <= range; k++) {
                const newRGB = {
                    r: Math.min(255, Math.max(0, targetRGB.r + i)),
                    g: Math.min(255, Math.max(0, targetRGB.g + j)),
                    b: Math.min(255, Math.max(0, targetRGB.b + k)),
                };

                const newHexCode = rgbToDecimalHex(newRGB);
                hexCodeArray.push(newHexCode);
            }
        }
    }
    
    return hexCodeArray;
    }

    export const encodeHTML = (s) => s?.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
    export const setProfileFirstAction = (action:ProfileFirstActions) => {
        return new Promise((resolve, reject) => {
            if (window.gtag)  window.gtag("event", "first_action_"+action, { value: action});
            resolve(true)
        })
        return new Promise((resolve, reject) => {
            try {
                const profile:ProfileInterface = (useLoginState.getState().profile as unknown) as ProfileInterface;
                if (!profile?.uid) {
                    resolve(false)
                } 
                else if (profile.firstActions==null || profile.firstActions.indexOf(action) < 0) {
                    // let first_actions_count =  parseInt(localStorage.getItem('first_actions_count')|| "0") + 1
                    // if (first_actions_count > Object.keys(ProfileFirstActions).length) {
                    //     throw new Error("Too many first actions"+ first_actions_count);
                    // }
                    // localStorage.setItem('first_actions_count', first_actions_count.toString())
                    const newProfile = produce(profile, draft=> {
                        if (draft.firstActions==null) draft.firstActions = [];
                        draft.firstActions.push(action)                    
                    })
                    const unique = [...new Set(newProfile.firstActions)];
                    if (unique.length != newProfile.firstActions?.length) {
                        throw new Error("Duplicate first actions");
                    }
                    useLoginState.setState({profile:newProfile as any})
                    console.log("Set First action ", action);
                    if (window.gtag)  window.gtag("event", "first_action_"+action, { value: action});

                    updateRecord(profile.id,'profiles', {firstActions:unique}).finally(()=>resolve(true));
                }
                else { resolve(false); }
            }
            catch (e) { console.log(e); reject(e); }
        })

    }

    export const gtagViewItemEvent = (product,variant?) => {
        if (window.gtag)  window.gtag("event", "view_item", {
            items: [
              {
                item_id: product?.id,
                item_name: product?.name,
                item_brand: product?.supplier,
                item_category: product?.category?.[0],
                item_category2: product?.category?.[1],
                item_category3: product?.category?.[2],      
                item_variant: variant ? variant : product?.color?.[0],
                item_category4: product?.color?.[1],
                item_category5: product?.color?.[2],
                item_list_id: product?.productType,
                item_list_name: product?.productType,
                affiliation: product?.supplierID,
              }
            ]
          });
	}
    export const gtagSearchEvent = (searchValue) => {
        if (window.gtag)  window.gtag("event", "search", {
            search_term: searchValue
        });
    }
    export const gtagFacetSearchEvent = (attribute, facetValue) => {
        if (window.gtag)  window.gtag("event", "Facetsearch", {
            facet_attribute: attribute,
            facet_search: facetValue
        });
    }

    export const gTagBrandClickEvent = (supplierID, supplier, destination, url) => {
        if(window.gtag) {
            window.gtag('event', 'SupplierClick', {
                brand_id: supplierID,
                brand_name: supplier,
                brand_click_type: destination,
                brand_click_url: url
            });
        }
    }

    export const fetchData = async (requestData, url) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
        
            if (!response.ok) {
                throw new Error('API request failed');
            }
        
            const responseData = await response.json();
            return responseData;
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
      
    export const getThumbnailFromTypesense = async (ids) => {
        const result = await fetch(`https://`+ typesenseHost + `/collections/productsV2/documents/search?q=*&per_page=${ids.length}&filter_by=id:[${ids}]`, {
            method: "GET",
            headers: {"Content-type": "application/json", "X-TYPESENSE-API-KEY": typesenseApiKey}
        });
        const jsonResponse = await result.json();
        return jsonResponse;
    }

export const  commonTypefaces = { 'Arial': 'Arial, sans-serif', 'Helvetica':'Helvetica, sans-serif',
'Georgia':'Georgia, serif','Courier New':'"Courier New", monospace','Lucida Console':'"Lucida Console", Monaco, monospace',
'Impact':'"Impact", Charcoal, sans-serif','Times New Roman':'"Times New Roman", Times, serif',
'Trebuchet':'"Trebuchet MS", Helvetica, sans-serif',
'Verdana':'"Verdana", Geneva, sans-serif','Montserat':'"Montserat", sans-serif'}

export const lineEndTypes = { 'None': 'none', 'Dot': 'dot', 'Dot Both Sides': 'dot-both-sides', 'Dot2': 'dot2', 'Dot2 Both Sides': 'dot2-both-sides',
'Dot3': 'dot3', 'Dot3 Both Sides': 'dot3-both-sides', 'Arrow': 'arrow', 'Arrow Both Sides': 'arrow-both-sides'}

export function base64ToBlob(base64: string, contentType: string) {
    const byteCharacters = atob(base64);
    const byteArrays: any = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }


export const zeroDecimalCurrencies = ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']
export function formatPrice(amount, currency='usd', showDecimals=true,style='currency' ) {
    if(!currency) return
    const args:any = { style:style, currency: currency }
    if (showDecimals==false) {
        args.minimumFractionDigits = 0;
        args.maximumFractionDigits = 0;
    }
    const formatCurrency = new Intl.NumberFormat('en-US', args);
    if (zeroDecimalCurrencies.includes(currency.toLowerCase())) return formatCurrency.format(amount)  
    else return  formatCurrency.format(amount / 100)
}


 