import React, { useRef, useState, lazy, useEffect, Suspense, useMemo } from 'react'
import useEventListener from '../libs/useEventListener';
import { useThree } from '@react-three/fiber'
// import { Perf } from 'r3f-perf';
// import { useControls,Leva,folder } from "leva"
// import {useMattoState,usePhysicalObjects,useProjectData} from '../MattoState'
// import {Vector2,LinearToneMapping,NoToneMapping,ReinhardToneMapping,CineonToneMapping,ACESFilmicToneMapping} from "three";
// import { invalidate }  from '@react-three/fiber'

// const loadR3fPerf = () => import('r3f-perf').then(module => module.Perf);
// const Perf = lazy(loadR3fPerf);

const Perf = lazy(() => import('r3f-perf').then(module => ({ default: module.Perf })));

// export const PostConfiguration = () => {

// 	const controls = useControls({ 
// 			// "Ambient":true, 
// 			tone: folder( { 
// 				"Options": {value:ACESFilmicToneMapping, options:{
// 						None:NoToneMapping, Linear:LinearToneMapping,
// 						Reinhard:ReinhardToneMapping,Cineon:CineonToneMapping,
// 						ACESFilm:ACESFilmicToneMapping
// 				}}, 
// 			}
// 			),
// 			ShadowParams: folder(
// 				 {
// 					// "radius":  {value: 4.5,min: 0,max: 25,step: 0.5, render:(get) => true},
// 					// "samples":  {value: 10,min: 0,max: 25,step: 1, render:(get) => true},
// 					// "bias": {value:-0.00029, min:-0.005,max:0.005, step:0.0001 },
// 					// "normalBias": {value:0, min:-0.05,max:0.05, step:0.0001 },
// 					"exposure": {value:1, min:0,max:2, step:0.01 },
					

// 					}
// 			),
// 			// Depth: folder(
// 			// 		{"DepthOfField":false, 
// 			// 		  "focalLength": {value: 1,min: 0,max: 1,step: 0.001},
// 			// 		  "focusDistance": {value:0, min:0, max:1, step:0.001}
// 			// 		}
// 			// ),
// 		 	PixelRatio: {value:2.0, min:0.9, max:3, step:0.1,render:(get) => false},
// 			ToneMapping: folder( { 
// 					"Options": {value:null, options:{
// 							None:NoToneMapping, Linear:LinearToneMapping,
// 							Reinhard:ReinhardToneMapping,Cineon:CineonToneMapping,
// 							ACESFilm:ACESFilmicToneMapping
// 					}, render:(get) => false}, 
// 			}
// 			)
// 	})

// 	useEffect(() => { 
// 		window.gl.toneMappingExposure=controls.exposure
// 		invalidate()
// 	}, [controls.exposure])

// 	useEffect(() => {
// 		window.gl.toneMapping=controls.Options || 4
// 		invalidate()

// 	}, [controls.Options])

// 	useEffect(() => {  
// 		console.log("Shadow bias values ", controls.bias, controls.normalBias);
		
// 		//useMattoState.setState({ post: controls }) 
	
// 	},[controls])
// 	return (null)
// }

// export const PostProcess = (props) => {
// 	const { scene, gl, size, camera,initialDpr,setDpr } = useThree();
// 	const postRef = useRef()
// 	const postControls = useMattoState((state) => state.post);      
// 	const isDirty = useMattoState( (state) => state.isStateDirty);
// 	const isRendering = useMattoState((state) => state.isRendering);        
// 	const doRender = postControls.InRealTime==true || isRendering==false

// 	// useEffect(() => {
// 	// 		gl.toneMapping = postControls.Options || 4
// 	// 		scene.traverse(object => { if(object.type === 'Mesh') object.material.needsUpdate = true; });
// 	// 		invalidate()
// 	// },[postControls.Options])

// 	useEffect(() => {
// 			if (postControls.PixelRatio != gl.getPixelRatio() && postControls.PixelRatio > 0.9) {
// 					gl.setPixelRatio(postControls.PixelRatio)
// 					invalidate()
// 			}
// 	},[postControls.PixelRatio])

// 	useEffect(() => { window.post = postRef },[postRef])

// 	const [showPerfControls, setShowPerfControls]=useState(false)
// 	const handleKeyDown = (e) => {
// 		if (e.target!=document.body) return;
// 		else if (e.keyCode==77 && e.shiftKey==true && (e.ctrlKey==true || e.altKey==true || e.metaKey==true)) setShowPerfControls(!showPerfControls);       
// 	}	
// 	useEventListener('keydown', handleKeyDown);

// 	return (
// 			<> 
// 	      {/* <EffectComposer ref={postRef} >
// 	      {postControls.Ambient==true && doRender && 
// 	        <SSAO
// 	        blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
// 	        radius={20}
// 	        intensity={15}

// 	        />
// 	      }

// 	      </EffectComposer> */}
// 			{showPerfControls==true && <PostConfiguration /> }
// 		  </>
// 	)

// 	// return (
// 	//      <EffectComposer ref={postRef} >
// 	//      </EffectComposer>
// 	// )
// 	// return (
// 	//      <EffectComposer ref={postRef} >
// 	//      {showPerfControls==true && <PostConfiguration /> }
// 	//      {postControls.Ambient==true && doRender && 
// 	//        <SSAO
// 	//        blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
// 	//        radius={8}
// 	//        intensity={11}    
// 	//        />
// 	//      }

// 	//      {postControls.DepthOfField==true && doRender &&
// 	//      <DepthOfField
// 	//        focusDistance={postControls.focusDistance || 0}
// 	//        focalLength={postControls.focalLength || 1}
// 	//        bokehScale={2}
// 	//        height={size.height}
// 	//        width={size.width}
// 	//      />
// 	//     }
// 	//      </EffectComposer>
// 	// )
// }


export const PerfHook = () => {
	const frameloop = useThree(state=>state.frameloop)
	const set = useThree(state => state.set)
	const [showPerfControls, setShowPerfControls]=useState(false)

	const handleKeyDown = (e) => {
		if (e.target!=document.body) return;
		else if (e.keyCode==80 && e.shiftKey==true && e.ctrlKey==true) { 
			set({ frameloop: frameloop=='always' ? 'demand' : 'always' })
		}
		else if (e.keyCode==77 && e.shiftKey==true && e.ctrlKey==true) { 
			setShowPerfControls( !showPerfControls)
		}
	}
	useEventListener('keydown', handleKeyDown);
	//const { gl, log } = usePerf();	
	return (
		<>
		{frameloop=='always' && <Perf  position={'top-left'}  />}
		</>
	)
};


// export const ShadowControls = () => {
// 	const { gl } = useThree()
// 	const setProjectData = useProjectData(state=>state.set)	
// 	const controls = useControls({
// 		shadow: {value:null, options:{"VSMShadowMap":VSMShadowMap,
// 		"PCFShadowMap":PCFShadowMap,
// 		"PCFSoftShadowMap":PCFSoftShadowMap,
// 		"BasicShadowMap": BasicShadowMap,
// 	  }},
// 		samples: { min:1, max:25, value:8},
// 		radius: {min: 0,max: 25,value:6,},
// 	})

// 	useEffect(() => {
// 		if (controls.shadow) gl.shadowMap.type = controls.shadow;
// 		setProjectData(state => { 
// 			state.lighting.samples  = controls.samples; 
// 			state.lighting.radius  = controls.radius; 
// 		})
// 	},[controls])

// 	invalidate()
// 	return null
// }


// export const BoxHelpers = () => {
// 	const controls = useControls({boxHelpers:false})
// 	useEffect(() => {
// 		if (controls.boxHelpers==true) {
// 			window.scene.children.forEach(obj => {
// 				if (obj.userData.physicalMesh==true) {
// 					console.log("Add box helper:",obj);
// 					const box = new BoxHelper(obj, 0xffff00 );
// 					window.scene.add(box)
// 				}
// 			})
// 		}
// 		else {
// 			const toRemove=[]
// 			window.scene.children.forEach(obj => {
// 				if (obj.type=='BoxHelper') {
// 					console.log('Remove bboxhelper:',obj);
// 					toRemove.push(obj)
// 				}
// 			})
// 			toRemove.forEach( i => window.scene.remove(i))						
// 		}
// 		invalidate()
// 	},[controls.boxHelpers])
// 	return (null)
// }


// export const PerformanceControls = () => {
// 	const controls= useControls({idlePixelIncrease:false,adaptivePixel:true, ssao:true, maxPixelRatio:{value: 1,min: 0.5,max: 10,step: 0.1,}})
// 	useEffect(() => {
// 		useMattoState.setState({ perf: controls })
// 	},[controls])
// 	return (null)
// }

// function AdaptivePixelRatio() {
//   const perfControls = useMattoState((state) => state.perf); //perf:{adaptivePixel:true, ssao:true, maxPixelRatio:1},
//   const { gl } = useThree()
//   useEffect(() => {
//   	gl.setPixelRatio(perfControls.maxPixelRatio)
//   }, [perfControls])
//   return null
// }

// export function PostProcessing() {
//   const perfControls = useMattoState((state) => state.perf);
//   return (
//   	<>
//   	{perfControls.ssao==true &&
//   		<Suspense  fallback={null}>
//      {/* <EffectComposer >
//       <SSAO blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
// 	  samples={30}
// 	  radius={32}
// 	  distanceThreshold={1.0} 
//       intensity={50}   />
//     </EffectComposer> */}
//     </Suspense>
// 	 }
// 	{perfControls.adaptivePixel==true &&
// 		<AdaptivePixelRatio />
// 	}
//     </>
//   )
// }