
export interface SupplierInterface {
    id:string,
    name:string,
    slug:string, //a url friendly version of the name
    URL?:string,
    email?:string,
    phone?:string,
    facebook_url?:string,
    instagram_url?:string,
    pinterest_url?:string,
    getInTouch_url?:string,
    description?:string,
    logo?:string,
    isActive?:boolean,
    projects?:string[],
    continent?:string[],
    updatedAt:number
}
export interface FulfillerInterface {
    id:string,
    name:string,
    email?:string,
    updatedAt:number
}
export interface PendingInvitationsInterface {
    id:string,
    supplierID:string,
    email:string,
    role: ProfileRole,
    updatedAt:number
}
export interface ProductCreation {
    id:string,
    productID:string,
    refImages?:string[], //urls for reference images
    comments:string,
    fulfiller:string, 
}
export interface NotificationsInterface {
    id:string,
    title: string,
    link: string,
    text: string,
    updatedAt:number
}
export interface NotificationsViewedInterface {
    id:string,
    user_id: string,
    notification_id: string,
    updatedAt:number
}
export enum  ObjectStatusType {
    APPROVED='APPROVED',
    APPROVED_PRO='APPROVED_PRO',
    REJECTED='REJECTED',
    PENDING='PENDING',    
    FULFILLED='FULFILLED',
    HIGH_PRIORITY="HIGH_PRIORITY",
    NEED_FULFILLMENT='NEED_FULFILLMENT',
    NEED_ASSIGNING="NEED_ASSIGNING",
    LOW_QUALITY="LOW_QUALITY",
    USER_CREATED='USER_CREATED',
    SUPPLIER_REJECTED="SUPPLIER_REJECTED",
    UNKNOWN="UNKNOWN"
}
export enum CategoryType {
    FOOD='Food',
    KNOBS='Knobs',
    TAPS='Taps',
    DECORATIONS='Decorations',
    OTHER='Other',
    PAINT='Paint',
    CANDLES='Candles',
    CRYSTAL='Crystal',
    FRAMES='Frames',
    GLASS='Glass',
    HANDLES='Handles',
    SOFT_FURNISHINGS="Soft Furnishings", 
    WALL_PANELS="Wall Panels",
    RECYCLED_MATERIALS="Recycled Materials",
    Vases="Vases",
    OBJECTS='Objects',
    ACCESSORIES='Accessories',
    FREEFORM='Freeform models',
    GEOMETRIC='Geometric',
    CIRCULAR='Circular',
    ORTHOGONAL='Orthogonal',
    FESTIVE='Festive',
    PLANTS='Plants',
    COFFEE='coffee',
    IRON='Iron Mongery',
    FABRIC='fabric',
    MARBLE='marble',
    TILE='tile',
    WOOD='wood',
    STONE='stone',
    LEATHER='leather',
    FLOORING='flooring',
    METAL='metal',
    METAL_GRANITE='marble - granite',
    ORGANIC='organic',
    TERRAZZO='terrazzo',
    CONCRETE='concrete',
    CARPET='carpet',
    WALLPAPER='wallpaper',
    WALLPAPER_MATTE='wallpaper_matte',
    WALLPAPER_GRASS='wallpaper_grass',
    PLASTIC='plastic',
    WORKTOP='worktop',
    PAINT_PLASTER_CERAMIC='paint/plaster/ceramic',
    PAINT_PLASTER='paint/paint/ceramic',
    WALL_MURAL='wall mural',
    POSTER='Poster',
    DECOR='Decor',
    LIGHTING='Lighting',
    LINOLEUM='Linoleum',
    WORKTOPS_CABINETRY='Worktops / Cabinetry',
    Interior='Interior',
    Exterior='Exterior'
}
export enum MaterialCategory {
    FABRIC='fabric',
    MARBLE='marble',
    TILE='tile',
    WOOD='wood',
    STONE='stone',
    LEATHER='leather',
    FLOORING='flooring',
    METAL='metal',
    METAL_GRANITE='marble - granite',
    PAINT='paint',
    ORGANIC='organic',
    TERRAZZO='terrazzo',
    CONCRETE='concrete',
    CARPET='carpet',
    WALLPAPER='wallpaper',
    WALLPAPER_MATTE='wallpaper_matte',
    WALLPAPER_GRASS='wallpaper_grass',
    PLASTIC='plastic',
    OTHER='other',
    WORKTOP='worktop',
    GLASS='glass',
    PAINT_PLASTER_CERAMIC='paint/plaster/ceramic',
    PAINT_PLASTER='paint/paint/ceramic',
    WALL_MURAL='wall mural',
    POSTER='Poster'
}

export enum MaterialColorType {
    WHITE='white',
    BLACK='black',
    GOLD='gold',
    SILVER='silver',
    BLUE='blue',
    BROWN='brown',
    GRAY='gray',
    GREEN='green',
    PINK='pink',
    RED='red',
    YELLOW='yellow',
    ORANGE='orange',
    PURPLE='purple',
    CHROME='chrome',
    NICKEL='nickel',
    BRONZE='bronze',
    GUNMETAL='gunmetal',
    BRASS='brass',
    ANTIQUE_BRASS='antique brass',
    BRUSHED_BRASS='brushed brass',
    BRUSHED_COPPER='brushed copper',
    TRANSLUCENT='translucent',
    MAGENTA='magenta',
    CYAN='cyan',
    TURQUOISE='turquoise',
    VIOLET='violet',
    LIME='lime',
    CORAL='coral',
    NEUTRAL='neutral',
    COBALT='cobalt',

    GRAY_NEUTRALS='Grays / Neutrals',
    MAGENTAS_REDS='Magentas / Reds',
    CORALS_ORANGES='Corals / Oranges',
    GOLDS_YELLOWS='Golds / Yellows',
    LIMES_GREENS='Limes / Greens',
    COBALTS_VIOLETS='Cobalts / Violets',
    TURQUOISES_BLUES='Turquoises / Blues'
}
export enum MaterialTextureType {
    HARD='hard',
    GLOSSY='glossy',
    ROUGH='rough',
    SOFT='soft',
    SMOOTH='smooth',
    MATTE='matte',
    NORMAL='normal',
    METAL='metal',
    TRANSPARENT='transparent',
    PLASTIC='plastic',
    GRAY='gray',
    WHITE='white'
}
export enum BrandContinentServed {
    ALL='All',
    NORTH_AMERICA='North America',
    SOUTH_AMERICA='South America',
    EUROPE='Europe',
    ASIA='Asia',
    OCEANIA='Oceania',
    AFRICA='Africa'
}
export interface MaterialData {
    id: string
    uid?:string
    name:string
    files?:MaterialFiles
    sourceColorFile?: string
    materialCategory?:MaterialCategory //already present in productinterface as category // remove it after confirmation that metasku is unused // only used at metasku.ts
    isTileable?:boolean
    materialProps?:MaterialProperties
    renderedImage?:string,
    materialType?:string,
    renders?:string[]
    aspectRatio?:number,
    userCreated?:boolean,
    preset?:string,  //a preset of materialproperties.  will be merged with materialProps
    presets?:string[],  //a set of possible presets
    originalColorInt?:number,  //the default color of the material before converted to ACES. Useful forr paints.
}
export interface MaterialProperties {
    roughness?: number,
    metalness?: number,
    displacementBias?: number,
    displacementScale?: number
    ior?: number,
    aoMapIntensity?: number
    envMapIntensity?: number
    reflectivity?: number
    clearcoat?: number
    transmission?: number
    normalScale?: Array<number>
    wireframe?: boolean
    textureRepeat?: number
    color?:any
    emissive?:any
    sheen?:number
    sheenTint?:any
    thickness?:number    
}

export interface ProductInterface {
    id:string
    name:string
    productType:ProductType
    objectStatus:ObjectStatusType
    dimension?:Dimensions
    relativePrice?:number  //1-5,  1 is cheapest, 5 is most expensive    
    supplier?:string
    supplierID?:string
    fulfiller?:string
    fulfillerID?:string
    updatedAt:number
    color?:MaterialColorType[]
    textureType?: MaterialTextureType[],
    category?:CategoryType[] | MaterialCategory[],
    materialData?:MaterialData
    mesh?:GLBMesh
    description?:string,
    refImages?:string[],
    rank?:number,
    sku?:string,
    version?:number,
    primaryColorInt?:number,
    isStaticObject:boolean,
    uid?:string,
    metadata?:ProductMetadata,
    productLink?:string,
    hue?: number,
    chroma?: number,
    luminance?: number
}
export interface ProductMetadata {
    url?:string,
    brand?:string,
    price_per_square_meter?:number,
}

export interface CollectionInterface {
    id:string
    name:string
    description:string
    coverImage:string //a single coverImage that overrides the thumbnails
    uid:string,
	products:ProductInterface["id"][] //this is string of product ids   <ProductInterface.id>[]
	userImages:CollectionUserImages[]
    userMaterials:CollectionUserMaterials[]
    protectedCollection:boolean
	createdAt:number
	updatedAt:number
    thumbnails?:CollectionThumbnail[]  //list of thumbnails to show in the collection for dispay purposes only. 
    projects?: CollectionProjectInterface[]
}
export interface CollectionThumbnail {
    id:ProductInterface["id"]
    src:string
}
export interface CollectionUserImages {
    id:string
    name:string
    src:string
    files:UserImagesFiles
}
export interface CollectionUserMaterials {
    id:string
    name:string
    src:string
}
export interface CollectionProjectInterface {
    id: string
    name: string
    thumbnail: string
    url: string
    user: string
    validURL?: boolean
    owner?: boolean
}
export interface UserImagesFiles {
    file?:string
    bg_removed_file?: string
}
export interface Dimensions {
    width:number,
    height:number,
    length:number
}
export enum MaterialType {
    PAINT='paint',
    MATERIAL='material'
}
export interface MaterialFiles {
    alpha_original?:string
    albedo_original?: string
    ambient_original?: string
    arm_original?: string
    color_original:any
    displacement_original?: string
    normal_original?: string
    roughness_original?: string
    metallic_original?: string
}
export interface GLBMesh {
    meshType:MeshTypes
    meshTextureRepeat?:number  //default is 1.  Used only is meshType is non_static
    rotation:Array<number>
    scale:Array<number>
    full_sized_glb:string, //full url to glb
    web_sized_glb:string, //full url to glb
    avif_glb?:string,  //glb url using avif textures.
    full_sized_vertices:number,
    web_sized_vertices:number,  //limited to 15000
    full_file_size:number,  //in bytes
    web_file_size:number,
    avif_file_size?:number,
    rendered_image:string, //url
    thumbnail:string,
    version?:number
}
export enum MeshTypes {
    STATIC='static',
    DYNAMIC='dynamic',
    PAINT='paint',
    SHAPE='shape',
    TEXT='text',
    GLTF='gltf',
    NOT_STATIC='not_static'
}

export enum ProductType {
    STATIC='static',  //A glb file that cannot apply textures or be modified. 
    NOT_STATIC='not_static',  //A product with a single geometry that cana apply materials.
    PAINT='paint',  
    MATERIAL='material',
    SHAPESHIFT='shapeshift',
    VARIANT='variant',
    PAINTOBJECT='paintObject',
    ANY='any',
    DYNAMIC='dynamic',
    SHAPE='Shape', //legacy
    CANVAS='canvas'
}
export enum MeasuringCategory {
    IMPERIAL = 'Imperial',
    METRIC = 'Metric'
}
export enum ProfileRole {
    ADMIN = 'admin',
    // FULFILLER = 'fulfiller',
    // SUPPLIER = 'supplier',
    OTHER = 'other',
    BRAND_ADMIN = 'brand_admin',
    // BRAND_COMMENTER = 'brand_commenter',
    BRAND_VIEWER = 'brand_viewer'

}

//this role is for the pro tier of the app.  It is not used yet.
export enum UserProfileRole {
    PRO='pro',
    BASIC='basic',
}

export enum ProfileFirstActions {
    DRAG_MATERIAL='drag_material',
    DRAG_PRODUCT='drag_product',
    DRAG_OBJECT='drag_object',
    DRAG_PAINT='drag_paint',
    DRAG_TEXT='drag_text',
    DRAG_LINE='drag_line',
    CLICK_MATERIAL='click_material',
    TRANSFORM_OBJECT='transform_object',
    CHANGE_BACKGROUND='change_background',
    VISIT_UPLOAD_MATERIAL='visit_upload_material',
    PROJECT_NEW='project_new',
    WELCOME_MOBILE='welcome_mobile',
    SAVE_BUTTON='save_button',
    OPEN_LIGHTING='open_lighting',
    OPEN_PAYMENT_MODAL='open_payment_modal',
    FINALIZE_PAYMENT_MODAL='finalize_payment_modal',
    PAYMENT_SUCCESS_MODAL='payment_success_modal',
}


export interface ProfileInterface {
    id?:string;
    first_name?:string;
    last_name?:string;
    measuring_category: MeasuringCategory;
    measuringCategory:MeasuringCategory; //dup screwed up here. 
    role?:ProfileRole;
    userRole?: UserProfileRole;
    company_name?:string
    company_size?:string
    industry?:string
    country:string;
    specialization?:string;
    occupation?:string;
    email:string;
    uid:string;
    supplierID?:string;
    fulfillerID?:string;
    created_at?:Date;
    updated_at?:Date; 
    createdAt?: Date    ;   
    category?:string;
    files?:{pic:string};
    stripeCustomerId?:string;
    stripeSubscriptionId?:string;
    trialends?:Date;
    mailingList:boolean;
    firstActions?:ProfileFirstActions[];
    renders:number;
}

export interface UploadFileInterface {
    name: string;
    bucket: string;
    id: string;
    currentImgUrl: string;
    isImage?: boolean;
    disabled: boolean;
    aspectRatio?: number;
    showImage: boolean;
    alwaysShowButton: boolean;
    preprocessFile?: (file: File) => Promise<File>;
    accept?: string;
    onUpload: (data: any, localImage?: any, fromStartingLoad?:boolean) => void;
}

export interface UploadMultipleFileInterface {
    name: string;
    bucket: string;
    id: string;
    currentImgUrl: string[];
    isImage?: boolean;
    disabled: boolean;
    aspectRatio?: number;
    showImage: boolean;
    alwaysShowButton: boolean;
    //    preprocessFile: (file: File) => Promise<File>;
    accept?: string;
    onUpload: (data: any, localImage?: any) => void;
}

export interface BrandFilterInterface {
    brand: string;
    count: number;
}

export interface CategoryFilterInterface {
    category: string;
    count: number;
}

export interface UserProductFiltersInterface {
    uid: string;
    brands?: BrandFilterInterface[];
    categories?: CategoryFilterInterface[];
}

declare global {
    interface Window {
        scene:any;
        simulateDrop:any
        gl:any
        camera:any
        debug:any
    }
    namespace JSX {
        interface IntrinsicElements {
            'tonetime-croptool':any;
        }
    }
}
//declare class CanvasPlus;
declare global {
    const CanvasPlus: any;
}


class GLBError extends Error {
    constructor(message) {
      super(message);      
      this.name = "GLBError"; 
    }
}


export function validateCollection(collection:CollectionInterface) {
    if (collection.id==null || collection.name==null ) { throw new Error('Collection must have an id and name'); }
    if (collection.uid==null ) { throw new Error('Collection must have a uid'); }
    if (collection.protectedCollection==null) { throw new Error('Collection must have a protectedCollection'); }
    if (collection.products==null) { throw new Error('Collection must have a products'); }
    for (const product of collection.products) {
        if (typeof product != 'string') { throw new Error('Collection products should be an array of strings'); }
    }
    if (collection.createdAt==null) { throw new Error('Collection must have a createdAt'); }
    if (collection.updatedAt==null) { throw new Error('Collection must have a updatedAt'); }
    if (collection.thumbnails && !Array.isArray(collection.thumbnails)) { throw new Error('Collection thumbnail should be an array'); }
    // if (collection.thumbnails) {
    //     for (const thumb  of collection.thumbnails) {
    //         if (typeof thumb != 'string' || (thumb as string).startsWith('http')===false ) { throw new Error('Collection thumbnail should be an array of strings'); }
    //     }
    // }
}

export function validateGLTBMesh(mesh:GLBMesh) {
    const meshTypes:any = Object.values(MeshTypes).sort()
    if (!meshTypes.includes(mesh.meshType)) { throw new Error('Mesh must have a valid meshType'); }
    if (!mesh.meshTextureRepeat || !(mesh.meshTextureRepeat > 0) ) { throw new Error('Mesh must have a meshTextureRepeat'); }
    if (!mesh.rotation  || mesh.rotation.length != 3 ) { throw new Error('Mesh must have a rotation'); }

    if (isNaN(mesh.rotation[0]) || isNaN(mesh.rotation[1]) || isNaN(mesh.rotation[2])   ) { throw new Error('Rotation must be a number'); }
    if (!mesh.scale || mesh.scale.length !=3 ) { throw new Error('Mesh must have a scale'); }
    if (isNaN(mesh.scale[0]) || isNaN(mesh.scale[1]) || isNaN(mesh.scale[2])   ) { throw new Error('Scale must be a number'); }

    if (!mesh.full_sized_glb ) { throw new Error('Mesh must have a full_sized_glb'); }
    if (!mesh.web_sized_glb ) { throw new Error('Mesh must have a web_sized_glb'); }
    if (!mesh.full_sized_vertices ) { throw new Error('Mesh must have a full_sized_vertices'); }
    if (!mesh.web_sized_vertices ) { throw new Error('Mesh must have a web_sized_vertices'); }
    if (!mesh.full_file_size ) { throw new Error('Mesh must have a full_file_size'); }
    if (!mesh.web_file_size ) { throw new Error('Mesh must have a web_file_size'); }
    if (!mesh.rendered_image ) { throw new Error('Mesh must have a rendered_image'); }    

    if (mesh.full_sized_glb.startsWith('http') == false) { throw new GLBError('Mesh must have a full_sized_glb that starts with http'); }
    if (mesh.web_sized_glb.startsWith('http') == false) { throw new GLBError('Mesh must have a full_sized_glb that starts with http'); }

}

export function validateMaterialData(materialData:MaterialData, isPaint=false) {
    if (isPaint==true) {
        if (!materialData.materialProps?.color) throw new Error('MaterialData must have a color for paint');
    }
    else {
        if (!materialData.files) { throw new Error('MaterialData must have a files'); }
        validateMaterialFiles(materialData.files)
        if (materialData.materialProps) validateMaterialProps(materialData.materialProps)
    }
}

export function validateMaterialProps(materialProps:any) {
    if (materialProps.thickness && materialProps.thickness < 0) { throw new Error('MaterialProps should have a thickness > 0'); }
    if (materialProps.sheenRoughness && materialProps.sheenRoughness < 0) { throw new Error('MaterialProps should have a SheenRoughness > 0'); }
    if (materialProps.metalness && materialProps.metalness < 0) { throw new Error('MaterialProps should have a Metalness > 0'); }
    if (materialProps.clearcoat && materialProps.clearcoat < 0) { throw new Error('MaterialProps should have a Clearcoat > 0'); }
    if (materialProps.displacementBias && isNaN(materialProps.displacementBias)) { throw new Error('MaterialProps displacement bias should be a number'); }
    if (materialProps.envMapIntensity && isNaN(materialProps.envMapIntensity)) { throw new Error('MaterialProps envMapIntensity should be a number'); }
    if (materialProps.reflectivity && isNaN(materialProps.reflectivity)) { throw new Error('MaterialProps reflectivity should be a number'); }
    if (materialProps.ior && isNaN(materialProps.ior)) { throw new Error('MaterialProps ior should be a number'); }
    if (materialProps.displacementScale && isNaN(materialProps.displacementScale)) { throw new Error('MaterialProps displacementScale should be a number'); }
    if (materialProps.transmission && isNaN(materialProps.transmission)) { throw new Error('MaterialProps transmission should be a number'); }
    if (materialProps.roughness && isNaN(materialProps.roughness)) { throw new Error('MaterialProps roughness should be a number'); }
    if (materialProps.aoMapIntensity && isNaN(materialProps.aoMapIntensity)) { throw new Error('MaterialProps aoMapIntensity should be a number'); }
    //checkIfString('emissive',materialProps.emissive)
    //checkIfString('color',materialProps.color)
    //checkIfString('sheenTint',materialProps.sheenTint)
    if (materialProps.normalScale && !Array.isArray(materialProps.normalScale)) { throw new Error('MaterialProps normalScale should be an array'); }
}

const checkIfString = (name:any,value:any) => {
    if (value && typeof value !== 'string') {
        const x = name + ' should be a string';
        throw new Error(x);
    }
}

export function validateMaterialFiles(materialFiles:MaterialFiles) {
    //if (!materialFiles.arm_original ) { throw new Error('MaterialFiles must have a arm_original'); }
    if (!materialFiles.color_original ) { throw new Error('MaterialFiles must have a color_original'); }
}



export function validateProduct(product:ProductInterface) {
    if (product.id==null || product.name==null ) { throw new Error('Product must have an id and name'); }
    if (product.rank &&  typeof product.rank=='string' ) { throw new Error('Product must have a valid rank that is an integer'); }
    
    const productTypes:any = Object.values(ProductType).sort()
    if (!productTypes.includes(product.productType)) { throw new Error('Product must have a valid productType'); }
    const objectStatusTypes:any = Object.values(ObjectStatusType).sort()
    if (!objectStatusTypes.includes(product.objectStatus)) { throw new Error('Product must have a valid objectStatus'); }
    const MaterialColorTypes:any = Object.values(MaterialColorType).sort().map(f=>f.toLowerCase())
    if (product.color) {
        for (const color of product.color) {
            if (!MaterialColorTypes.includes(color?.toLowerCase())) { 
                throw new Error('Product must have a valid materialColor ' + product.color); }
        }
    }
    if (product.primaryColorInt != undefined && isNaN(product.primaryColorInt)) {  throw new Error('Product must have a valid primaryColorInt'); }

    
    if (product.objectStatus!=ObjectStatusType.USER_CREATED) {
        // if (product.supplierID==null || product.supplier==null) { throw new Error('Product must have a supplierID and supplier'); }
        // if (product.supplierID=='new') { throw new Error('Product must have a valid supplierID'); }
        // if (product.supplier.length < 2) { throw new Error('Product must have a valid supplier'); }
    }


    const MaterialTextureTypes:any = Object.values(MaterialTextureType).sort()
    if (product.textureType) {
        for (const texture of product.textureType) {
            if (!MaterialTextureTypes.includes(texture.toLowerCase())) { 
                throw new Error('Product must have a valid materialTexture'); 
            }
        }
    }
    const CategoryTypes:any = Object.values(CategoryType).sort()
    if (product.category && (product.productType==ProductType.STATIC)) {
        for (const category of product.category) {
            if (!CategoryTypes.includes(category)) {                 
                throw new Error('Product must have a valid category'); 
            }
        }

    }

    const MaterialCategoryTypes:any = Object.values(MaterialCategory).sort()
    if (product.category && (product.productType==ProductType.MATERIAL || product.productType==ProductType.NOT_STATIC)) {
        for (const category of product.category) {
            if (!MaterialCategoryTypes.includes(category.toLowerCase()) && !CategoryTypes.includes(category)) { 
                throw new Error('Product must have a valid category'); 
            }
        }
    }
    // if (product.materialCategory) {
    //     for (const category of product.materialCategory) {
    //         if (!MaterialCategoryTypes.includes(category.toLowerCase())) { 
    //             // console.log("category", category)
    //             throw new Error('Product must have a valid materialCategory'); 
    //         }
    //     }
    // }
    if (  (product.productType=='static' ||   product.productType =='not_static') &&  !product.mesh) { 
        throw new Error('Product must have a mesh'); 
    }
    if (product.productType!=ProductType.MATERIAL && product.productType!=ProductType.PAINT) {    
        if (  ! (product.isStaticObject==true || product.isStaticObject==false)) { throw new Error('Product must have an isStaticObject'); }
        if (product.mesh) validateGLTBMesh(product.mesh)
    }
    
    if (product.productType== ProductType.MATERIAL) {
        if (!product.materialData) { throw new Error('Product must have a materialData'); }
        validateMaterialData(product.materialData)
    }
}

export enum TopSuppliers {
    tec2 = "2tec2", 
    ThreeM = "3M", 
    form = "3form", 
    Spaces = "4Spaces", 
    Wood = "9Wood", 
    ACRE = "ACRE", 
    AGROB = "AGROB", 
    AHF = "AHF", 
    AKDO = "AKDO", 
    ANOVA = "ANOVA", 
    AQ = "AQ", 
    ARTIZIN = "ARTIZIN", 
    ASCALE = "ASCALE", 
    ASIArchitectural = "ASI Architectural", 
    AVA = "AVA", 
    Aayers = "Aayers", 
    Abitex = "Abitex", 
    Acoufelt = "Acoufelt", 
    Acoustical = "Acoustical", 
    Adex = "Adex", 
    Adore = "Adore", 
    Advantage = "Advantage", 
    AiNO = "AiNO", 
    Akouo = "Akouo", 
    Allstate = "Allstate", 
    Altro = "Altro", 
    Altyno = "Altyno", 
    Alvic = "Alvic", 
    Amara = "Amara", 
    American = "American", 
    Amorim = "Amorim", 
    Ampro = "Ampro", 
    Ann = "Ann", 
    Annie = "Annie", 
    Anthology = "Anthology", 
    Antolini = "Antolini", 
    Anzea = "Anzea", 
    Arauco = "Arauco", 
    Arbor = "Arbor", 
    Arborite = "Arborite", 
    Architectural = "Architectural", 
    Architessa = "Architessa", 
    Architex = "Architex", 
    Arim = "Arim", 
    Arktura = "Arktura", 
    Armstrong = "Armstrong", 
    Artaic = "Artaic", 
    Article = "Article", 
    Artisan = "Artisan", 
    Artistic = "Artistic", 
    Artistry = "Artistry", 
    Arto = "Arto", 
    Aspecta = "Aspecta", 
    Asrin = "Asrin", 
    Astek = "Astek", 
    Atelier = "Atelier", 
    Atlas = "Atlas", 
    Autex = "Autex", 
    BASWA = "BASWA", 
    BOTTEGA = "BOTTEGA", 
    Baldwin = "Baldwin", 
    Balsan = "Balsan", 
    Bandalux = "Bandalux", 
    Barbarossa = "Barbarossa", 
    Baseboarders = "Baseboarders", 
    Beaver = "Beaver", 
    Bedrosians = "Bedrosians", 
    Belden = "Belden", 
    Belgard = "Belgard", 
    BenjaminMoore = "Benjamin Moore", 
    Bergamo = "Bergamo", 
    Bernhardt = "Bernhardt", 
    Bilotta = "Bilotta", 
    Biyork = "Biyork", 
    Bloomsburg = "Bloomsburg", 
    Bodaq = "Bodaq", 
    Boen = "Boen", 
    Bolon = "Bolon", 
    Bradley = "Bradley", 
    Brentano = "Brentano", 
    Brick = "Brick", 
    Brizo = "Brizo", 
    Buechel = "Buechel", 
    BuildDirect = "BuildDirect", 
    Burch = "Burch", 
    BuzziSpace = "BuzziSpace", 
    CARVART = "CARVART", 
    CF = "CF", 
    COVERINGS = "COVERINGS", 
    CRAFT = "CRAFT", 
    CTL = "CTL", 
    Cabinet = "Cabinet", 
    Caesarstone = "Caesarstone", 
    Cafe = "Cafe", 
    Calyx = "Calyx", 
    Cambria = "Cambria", 
    Cancos = "Cancos", 
    Capri = "Capri", 
    Carlisle = "Carlisle", 
    Carnegie = "Carnegie", 
    Cascade = "Cascade", 
    Ceilume = "Ceilume", 
    Centennial = "Centennial", 
    Ceramic = "Ceramic", 
    CertainTeed = "CertainTeed", 
    Chemetal = "Chemetal", 
    Chesapeake = "Chesapeake", 
    Clarus = "Clarus", 
    Cobalt = "Cobalt", 
    Color = "Color", 
    Compac = "Compac", 
    Concept = "Concept", 
    Concrete = "Concrete", 
    Conestoga = "Conestoga", 
    Conwed = "Conwed", 
    Cooper = "Cooper", 
    Coordonné = "Coordonné", 
    Corso = "Corso", 
    Cortina = "Cortina", 
    Cosentino = "Cosentino", 
    Coulisse = "Coulisse", 
    Country = "Country", 
    Coveworks = "Coveworks", 
    Coycama = "Coycama", 
    Craft = "Craft", 
    Crossville = "Crossville", 
    Crypton = "Crypton", 
    Cupa = "Cupa", 
    Curator = "Curator", 
    Curava = "Curava", 
    DUCHATEAU = "DUCHATEAU", 
    Daltile = "Daltile", 
    Dauphin = "Dauphin", 
    David = "David", 
    De = "De", 
    Decorative = "Decorative", 
    Delta = "Delta", 
    Demar = "Demar", 
    Design = "Design", 
    DevonDevon = "Devon&Devon", 
    Dinoflex = "Dinoflex", 
    Divine = "Divine", 
    Division = "Division", 
    Draper = "Draper", 
    DreamScape = "DreamScape", 
    Dreamwalls = "Dreamwalls", 
    Drop = "Drop", 
    DunnEdwards = "Dunn-Edwards", 
    Durasein = "Durasein", 
    Durat = "Durat", 
    Dynamic = "Dynamic", 
    ECONYL = "ECONYL", 
    EF = "EF", 
    EQUITONE = "EQUITONE", 
    Eco = "Eco", 
    Ecore = "Ecore", 
    Ege = "Ege", 
    Eleganza = "Eleganza", 
    Element = "Element", 
    Emser = "Emser", 
    Emtek = "Emtek", 
    Endless = "Endless", 
    Endure = "Endure", 
    Ethnicraft = "Ethnicraft", 
    Everest = "Everest", 
    Evoke = "Evoke", 
    Expormim = "Expormim", 
    EzoBord = "EzoBord", 
    FENIX = "FENIX", 
    FLOR = "FLOR", 
    FSorb = "FSorb", 
    Fabric = "Fabric", 
    Feeney = "Feeney", 
    Felli = "Felli", 
    Fi = "Fi", 
    Fidelity = "Fidelity", 
    Fine = "Fine", 
    Fioretta = "Fioretta", 
    Fireclay = "Fireclay", 
    Flexco = "Flexco", 
    Floor = "Floor", 
    Flooret = "Flooret", 
    Florida = "Florida", 
    Foglizzo = "Foglizzo", 
    Forbo = "Forbo", 
    Foresso = "Foresso", 
    Formica = "Formica", 
    Forth = "Forth", 
    Frasch = "Frasch", 
    Frontier = "Frontier", 
    Fundermax = "Fundermax", 
    GAR = "GAR", 
    GENROSE = "GENROSE", 
    GEOS = "GEOS", 
    GGI = "GGI", 
    Gancedo = "Gancedo", 
    Garden = "Garden", 
    Garrett = "Garrett", 
    GemCore = "GemCore", 
    Geolam = "Geolam", 
    Gerflor = "Gerflor", 
    Gilasi = "Gilasi", 
    Goldray = "Goldray", 
    Goodwin = "Goodwin", 
    Grand = "Grand", 
    Grato = "Grato", 
    Green = "Green", 
    Greenlam = "Greenlam", 
    GrowUp = "GrowUp", 
    Gruppo = "Gruppo", 
    HALCON = "HALCON", 
    HD = "HD", 
    HFLOR = "HFLOR", 
    HIMACS = "HIMACS", 
    HOLLY = "HOLLY", 
    Hamilton = "Hamilton", 
    HanStone = "HanStone", 
    Hanex = "Hanex", 
    Haustile = "Haustile", 
    Havwoods = "Havwoods", 
    Haworth = "Haworth", 
    HempWood = "HempWood", 
    Herculite = "Herculite", 
    Hero = "Hero", 
    Highstyle = "Highstyle", 
    HitLights = "HitLights", 
    IB = "IB", 
    IKONNI = "IKONNI", 
    IMARK = "IMARK", 
    INAX = "INAX", 
    Impact = "Impact", 
    IndoTeak = "IndoTeak", 
    Indusparquet = "Indusparquet", 
    Industry = "Industry", 
    Infinity = "Infinity", 
    IntelliForce = "Intelli-Force", 
    Interface = "Interface", 
    Interior = "Interior", 
    Italgres = "Italgres", 
    JJ = "J+J", 
    JSI = "JSI", 
    Jacaranda = "Jacaranda", 
    Jaipur = "Jaipur", 
    James = "James", 
    Jamie = "Jamie", 
    Jeffrey = "Jeffrey", 
    Jerry = "Jerry", 
    Jung = "Jung", 
    Justin = "Justin", 
    KAZA = "KAZA", 
    KB = "KB", 
    KLEIN = "KLEIN", 
    KØL = "KØL", 
    Kafka = "Kafka", 
    Kahrs = "Kahrs", 
    Kale = "Kale", 
    Karen = "Karen", 
    Karndean = "Karndean", 
    Kaynemaile = "Kaynemaile", 
    KegWorks = "KegWorks", 
    Keilhauer = "Keilhauer", 
    Kentwood = "Kentwood", 
    Kimball = "Kimball", 
    Kingspan = "Kingspan", 
    Kingston = "Kingston", 
    Kirei = "Kirei", 
    Koleksiyon = "Koleksiyon", 
    Konkretus = "Konkretus", 
    Koroseal = "Koroseal", 
    Koskisen = "Koskisen", 
    Kravet = "Kravet", 
    Kriskadecor = "Kriskadecor", 
    Küberit = "Küberit", 
    Kyle = "Kyle", 
    LM = "L&M", 
    LAMINAM = "LAMINAM", 
    LAMVIN = "LAMVIN", 
    LATICRETE = "LATICRETE", 
    LBI = "LBI", 
    LEA = "LEA", 
    LEKTRAFLOR = "LEKTRAFLOR", 
    LIVDEN = "LIVDEN", 
    LOOMINOLOGY = "LOOMINOLOGY", 
    LUMA = "LUMA", 
    La = "La", 
    Lab = "Lab", 
    Laminart = "Laminart", 
    Landmark = "Landmark", 
    Lapitec = "Lapitec", 
    Laura = "Laura", 
    Laurent = "Laurent", 
    LebaTex = "LebaTex", 
    Ledalite = "Ledalite", 
    Legno = "Legno", 
    Leland = "Leland", 
    LiLi = "LiLi", 
    Life = "Life", 
    LightArt = "LightArt", 
    Lightolier = "Lightolier", 
    Lightwave = "Lightwave", 
    Liquid = "Liquid", 
    Living = "Living", 
    LivingStone = "LivingStone", 
    Loloi = "Loloi", 
    LondonArt = "LondonArt", 
    Longboard = "Longboard", 
    Louisville = "Louisville", 
    Lucetta = "Lucetta", 
    Lucida = "Lucida", 
    Lundhs = "Lundhs", 
    Lutron = "Lutron", 
    Luxe = "Luxe", 
    M2 = "M2", 
    MADERA = "MADERA", 
    MCM = "MCM", 
    MDC = "MDC", 
    MILE = "MILE", 
    MLW = "MLW", 
    MPS = "MPS", 
    MSI = "MSI", 
    Madrid = "Madrid", 
    Mannington = "Mannington", 
    Marazzi = "Marazzi", 
    Marble = "Marble", 
    Mataverde = "Mataverde", 
    Materials = "Materials", 
    Maya = "Maya", 
    McKinnon = "McKinnon", 
    Mecho = "Mecho", 
    Meganite = "Meganite", 
    Menconi = "Menconi", 
    Mercury = "Mercury", 
    Mermet = "Mermet", 
    MetalUnic = "MetalUnic", 
    Metrobrick = "Metrobrick", 
    Metroflor = "Metroflor", 
    Metropolitan = "Metropolitan", 
    Milliken = "Milliken", 
    Mirage = "Mirage", 
    Mirth = "Mirth", 
    Mitchell = "Mitchell", 
    Modern = "Modern", 
    Mohawk = "Mohawk", 
    Moisture = "Moisture", 
    Momentum = "Momentum", 
    Monarch = "Monarch", 
    Mondani = "Mondani", 
    Mondo = "Mondo", 
    Monogram = "Monogram", 
    Monon = "Monon", 
    Montana = "Montana", 
    Moore = "Moore", 
    Mosa = "Mosa", 
    Moz = "Moz", 
    Muraflex = "Muraflex", 
    NINE = "NINE", 
    NOW = "NOW", 
    Nasco = "Nasco", 
    National = "National", 
    Nawkaw = "Nawkaw", 
    Nemo = "Nemo", 
    Nevamar = "Nevamar", 
    NewAge = "NewAge", 
    NewTechWood = "NewTechWood", 
    Nienkamper = "Nienkamper", 
    Nomadory = "Nomadory", 
    North = "North", 
    Northern = "Northern", 
    Nova = "Nova", 
    NuWud = "NuWud", 
    Nuvelle = "Nuvelle", 
    Nydree = "Nydree", 
    Oceanside = "Oceanside", 
    Olivia = "Olivia", 
    Omni = "Omni", 
    OmniDecor = "OmniDecor", 
    Opposite = "Opposite", 
    Optima = "Optima", 
    Orac = "Orac", 
    Organoid = "Organoid", 
    PKaufmann = "P/Kaufmann", 
    POINT = "POINT", 
    PORSLIM = "PORSLIM", 
    PURLINE = "PURLINE", 
    Pallas = "Pallas", 
    Pantheon = "Pantheon", 
    PaperStone = "PaperStone", 
    Parador = "Parador", 
    Parkay = "Parkay", 
    Parklex = "Parklex", 
    Parterre = "Parterre", 
    Paul = "Paul", 
    PebbleTec = "PebbleTec", 
    Penelopeoggi = "Penelopeoggi", 
    Perlato = "Perlato", 
    Phenix = "Phenix", 
    Pioneer = "Pioneer", 
    Pionite = "Pionite", 
    Pittet = "Pittet", 
    Planthropy = "Planthropy", 
    Platform = "Platform", 
    Platt = "Platt", 
    PoliLam = "PoliLam", 
    Poltrona = "Poltrona", 
    Polygood = "Polygood", 
    Polyvision = "Polyvision", 
    Porcelanosa = "Porcelanosa", 
    Portobello = "Portobello", 
    Posh = "Posh", 
    Prima = "Prima", 
    Pushka = "Pushka", 
    Qwel = "Qwel", 
    RAD = "RAD", 
    REGUPOL = "REGUPOL", 
    RIVA = "RIVA", 
    Raphael = "Raphael", 
    Raskin = "Raskin", 
    Realstone = "Realstone", 
    Reclaimed = "Reclaimed", 
    Reggio = "Reggio", 
    Rehau = "Rehau", 
    Relative = "Relative", 
    Reward = "Reward", 
    Rex = "Rex", 
    Riad = "Riad", 
    Richards = "Richards", 
    Richlite = "Richlite", 
    Richloom = "Richloom", 
    Rigidized = "Rigidized", 
    Riken = "Riken", 
    Rikett = "Rikett", 
    Ritz = "Ritz", 
    Robert = "Robert", 
    Rockfon = "Rockfon", 
    Rocky = "Rocky", 
    Rollout = "Rollout", 
    Rookwood = "Rookwood", 
    Roppe = "Roppe", 
    Rous = "Rous", 
    SKYX = "SKYX", 
    STELAPOP = "STELAPOP", 
    STEVENSWOOD = "STEVENSWOOD", 
    SWFcontract = "SWFcontract", 
    Saiens = "Saiens", 
    Samelson = "Samelson", 
    Samur = "Samur", 
    Santamargherita = "Santamargherita", 
    Schonbek = "Schonbek", 
    Scuffmaster = "Scuffmaster", 
    SelecTech = "SelecTech", 
    Sensitile = "Sensitile", 
    Serge = "Serge", 
    SherwinWilliams = "Sherwin Williams", 
    ShimmerScreen = "ShimmerScreen", 
    Sicis = "Sicis", 
    SieMatic = "SieMatic", 
    Siena = "Siena", 
    Six = "Six", 
    Skyline = "Skyline", 
    Smile = "Smile", 
    Snowsound = "Snowsound", 
    Soelberg = "Soelberg", 
    SomerTile = "SomerTile", 
    SoundPly = "SoundPly", 
    Southland = "Southland", 
    Specialty = "Specialty", 
    Specified = "Specified", 
    Spoonflower = "Spoonflower", 
    Springs = "Springs", 
    Standard = "Standard", 
    Stark = "Stark", 
    Staron = "Staron", 
    StaticWorx = "StaticWorx", 
    Steelscape = "Steelscape", 
    Stellar = "Stellar", 
    Stikwood = "Stikwood", 
    Stockton = "Stockton", 
    Stone = "Stone", 
    Stoneline = "Stoneline", 
    Stonhard = "Stonhard", 
    StratusQuartz = "StratusQuartz", 
    Studio = "Studio", 
    StyleNations = "StyleNations", 
    Sunbrella = "Sunbrella", 
    Surface = "Surface", 
    Surfacing = "Surfacing", 
    Swan = "Swan", 
    Symmons = "Symmons", 
    Symphony = "Symphony", 
    TAJ = "TAJ", 
    Tafisa = "Tafisa", 
    Takeform = "Takeform", 
    Tarkett = "Tarkett", 
    Teknoflor = "Teknoflor", 
    Terra = "Terra", 
    TerraMai = "TerraMai", 
    Terrazzo = "Terrazzo", 
    Terrazzotto = "Terrazzotto", 
    Texstyle = "Texstyle", 
    Texture = "Texture", 
    Three = "Three", 
    Tile = "Tile", 
    TileBar = "TileBar", 
    Tiles = "Tiles", 
    To = "To", 
    Townsend = "Townsend", 
    TredMOR = "Tred-MOR", 
    Trendy = "Trendy", 
    Trespa = "Trespa", 
    Trex = "Trex", 
    Trinity = "Trinity", 
    Trivantage = "Trivantage", 
    True = "True", 
    Trueform = "Trueform", 
    Turf = "Turf", 
    UFPEdge = "UFP-Edge", 
    USG = "USG", 
    USM = "USM", 
    Unika = "Unika", 
    UpScapers = "UpScapers", 
    Urban = "Urban", 
    VICWEST = "VICWEST", 
    VIGO = "VIGO", 
    VITL = "VITL", 
    Vadara = "Vadara", 
    Valiant = "Valiant", 
    Valley = "Valley", 
    Vermont = "Vermont", 
    Verna = "Verna", 
    Vertically = "Vertically", 
    Vescom = "Vescom", 
    Vetrazzo = "Vetrazzo", 
    Viatera = "Viatera", 
    Vitro = "Vitro", 
    WAC = "WAC", 
    Walker = "Walker", 
    Walldecò = "Wall&decò", 
    Wallquest = "Wallquest", 
    Watson = "Watson", 
    Wayne = "Wayne", 
    WildLeaf = "WildLeaf", 
    Wilsonart = "Wilsonart", 
    WolfGordon = "Wolf-Gordon", 
    WoodChic = "WoodChic", 
    WoodCo = "WoodCo", 
    Woodpecker = "Woodpecker", 
    Woodwol = "Woodwol", 
    Woodwright = "Woodwright", 
    Workrite = "Workrite", 
    Yo2 = "Yo2", 
    Zahner = "Zahner", 
    Zandur = "Zandur", 
    deko = "deko", 
    elementAl = "elementAl", 
    felt = "felt", 
    glass2 = "glass2", 
    hushacoustics = "hush.acoustics", 
    iSiMAR = "iSiMAR", 
    imagine = "imagine", 
    lightly = "lightly", 
    mafi = "mafi", 
    nora = "nora", 
    van = "van",
    WallArt='Wall Art'
}