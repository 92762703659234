import { Button, CircularProgress, Divider, Modal, Stack, TextField, Typography } from "@mui/material"
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMattoState } from "../../../MattoState";
import { CollectionInterface, CollectionProjectInterface, CollectionUserImages, CollectionUserMaterials } from "ts-interfaces";
import { useFormik } from "formik";
import { encodeHTML, generateID } from "../../../libs/util";
import { AuthContext } from "../../../auth/Auth";
import { getDocByID, getUrlFromRef, projectsDB } from "../../../libs/firebase";
import { cloudflareCDN } from "ui";
import { produce } from "immer";
import { useLoginState } from "../../auth/LoginState";
import { ProfileUpload } from "../../../components/ProfileUpload";
import { ActionButton, ActionButtonSecondary } from "../../../buttons/ButtonStyles";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';


const textFieldProps:any = { borderradius:'5px', variant: "outlined", margin: "normal", size: 'small', fullWidth: true, type: "text", sx: { paddingRight: '10px',width:'98%' }}

const style = {
    position: 'absolute' as const,
    top: '50%',left: '50%',
    transform: 'translate(-50%, -50%)',width: 400,
    bgcolor: 'background.paper',borderRadius: 2,
    boxShadow: 24,p: 4,
};

interface CollectionModalProps {
    name: string;
    id: string;
    product?: string;
    userImage?: CollectionUserImages;
    userMaterial?: CollectionUserMaterials;
}
export const CollectionModal = () => {
    const {currentUser} = useContext(AuthContext);
    const modal:CollectionModalProps | any = useMattoState((state) => state.modal);
    const isCollectionModal = modal?.name=='collection';
    if (!isCollectionModal || currentUser==null) return null;
    else { return <NewCollectionModal /> }
}
export const NewCollectionModal = () => {
    const {currentUser} = useContext(AuthContext);
    const modal:CollectionModalProps | any = useMattoState((state) => state.modal);
    const isCollectionModal = modal?.name=='collection';
    const isNewCollection = modal?.id==null;
	  const setModal = useMattoState((state) => state.setModal);
    const handleClose = () => void setModal(null);
    const [saving,setSaving] = useState(false);
    const [collection,setCollection] = useState<CollectionInterface | null>();
    const [coverImage,setCoverImage] = useState<any>(null);
	  const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);
    const [errors, setErrors]= useState<any>({})
    const addOrUpdateCollection = useLoginState ((state) => state.addOrUpdateCollection)
    const [projects, setProjects] = useState<any>([]);

    const formik:any = useFormik({
        initialValues: {
            name: collection?.name || '',
            description: collection?.description || '',
        },
        onSubmit: values => {  },
    });
    useEffect(() => {
        setSaving(false); setErrors({}); setSnackbarMessage(null);
        if (modal?.name=='collection' && modal?.id) {
            getDocByID('usersMyCollection',modal.id).then((doc:any) => {
                if (doc.exists) {
                    const collection:CollectionInterface = doc.data() as CollectionInterface
                    formik.values.name = collection.name;
                    formik.values.description = collection.description;
                    setCollection(doc.data() as CollectionInterface)
                    if(collection?.projects) {
                        collection.projects.forEach((project: CollectionProjectInterface) => {
                            project.validURL = true;
                            project.owner = project.user == currentUser?.uid;
                        })
                        setProjects(collection.projects);
                    }
                }
            })
        }
        else if (currentUser) { setCollection(createNewCollection(currentUser?.uid)) }
        else { setCollection(null)}
    },[modal])  

    const vaildate = () => {
        const err:any = {} 
        if (!formik.values.name || formik?.values?.name?.length < 3)  err.name = 'Please enter a name for your collection'
        if (formik?.values?.description?.length > 300)  err.description = 'Description cannot be more than 300 characters.'
        if(projects.length > 0) {
            for (let i = 0; i < projects.length; i++) {
                if (!projects[i]?.validURL || !projects[i]?.thumbnail) {
                    err.projects = 'Invalid Project URL'
                    break;
                }
                if(projects[i]?.user && projects[i]?.user != currentUser?.uid) {
                    err.projects = 'You can only add your own projects'
                    break;
                }
            }
        }
        if (Object.keys(err).length) { setErrors(err); return false; }
        else return true;
    }
    const handleSave = async () => {
        setErrors({})
        if (!vaildate()) return;
        setSaving(true);

        const newCollection:CollectionInterface = produce(collection, (draft:CollectionInterface) => {
            draft.name = encodeHTML(formik.values.name) || '';
            draft.description = encodeHTML(formik.values.description) || '';
            draft.updatedAt = Date.now();
            if (modal.product) {
                if (!draft.products)  draft.products = []
                if (!draft.products.includes(modal.product.id)) {
                    draft.products.push(modal.product.id)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.product.id,src:modal.product.src})
                }
            }        
            if (modal.userImage) {
                if (!draft.userImages) draft.userImages = []
                if (!draft.userImages.includes(modal.userImage)) {
                    draft.userImages.push(modal.userImage)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.userImage.id,src:modal.userImage.src})
                }
            }
            if(modal.userMaterial) {
                if (!draft.userMaterials) draft.userMaterials = []
                if (!draft.userMaterials.includes(modal.userMaterial)) {
                    draft.userMaterials.push(modal.userMaterial)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.userMaterial.id,src:modal.userMaterial.src})
                }
            }
            if(projects.length > 0) {
                draft.projects = projects.map((project) => {
                    return {id: project.id, name: project.name, thumbnail: project.thumbnail, url: project.url, user: project.user}
                })
            }
        }) as CollectionInterface
        setCollection(newCollection);

        addOrUpdateCollection(newCollection, coverImage).then(() => {
            if (modal.product) useMattoState.getState().setSnackbarMessage(`${modal?.product?.name} updated in ${newCollection.name}`);            
            else if (modal.userImage) useMattoState.getState().setSnackbarMessage(`Image updated in ${newCollection.name}`);
            else if (modal.userMaterial) useMattoState.getState().setSnackbarMessage(`${modal?.userMaterial?.name} updated in ${newCollection.name}`);
            else useMattoState.getState().setSnackbarMessage(`Collection ${newCollection.name} saved`);
        })
        .catch(err =>  {
            console.log("Error Saving Collection!" + err)
            useMattoState.getState().setSnackbarMessage(`Error Saving Collection! ${err}`)
        })
        .finally( () => { setSaving(false); handleClose() })
    }
    const handleCoverImageUpload = (blob) => {if (blob) setCoverImage(blob)    }

    const handleCoverImageDelete = () => { setCoverImage(null)    }


    const handleShareCollection = () => {
        if (collection) {
            navigator.clipboard.writeText(`${window.location.origin}/project/new?collectionID=${collection.id}`)
            setSnackbarMessage(`Collection URL copied to clipboard`)
        }
    }

    const addTextBox = (e) => {
        e.preventDefault();
        setProjects([...projects, {id:null,url:'',thumbnail:null,validURL:true,isLoading:false}]);
      };
  
      const removeTextBox = (index) => {
        const updatedTextBoxes = [...projects];
        updatedTextBoxes.splice(index, 1);
        setProjects(updatedTextBoxes);
      };
    
      const handleTextBoxChange = (e) => {
        const index = e.target.id.slice(-1)
        const value = e.target.value
        const updatedTextBoxes = [...projects];
        updatedTextBoxes[index]=  {id:null,url:value,thumbnail:null,validURL:true,isLoading:false};
        setProjects(updatedTextBoxes);
      };
      const handleTextBoxBlur = (e) => {
        const index = e.target.id.slice(-1)
        const value = e.target.value
        const id = value?.split('?')[0].split('/').pop()
        if(!id || id?.length <= 0 || (projects[index]?.id == id && projects[index]?.thumbnail)) return;
        const updatedTextBoxes = [...projects];
        updatedTextBoxes[index].isLoading = true;
        setProjects(updatedTextBoxes);
        loadProjectData(id,index,value)
      };

      const loadProjectData = async(id,index,value) => {
        const updatedTextBoxes = [...projects];
        getDocByID("projects", id).then((doc:any) => {
            if (!doc.exists) {
                updatedTextBoxes[index].validURL = false;
                return;
            }
            const data = doc.data();
            updatedTextBoxes[index] = { id:id, url:value, name: data?.projectData?.title, user: data?.user, thumbnail:data?.files?.screenshot, validURL:true, owner: data?.user == currentUser?.uid};
        })
        .catch((error) => { 
            console.log("Error getting project: ", error);   
            updatedTextBoxes[index].validURL = false;
          })
        .finally(() => {
            updatedTextBoxes[index].isLoading = false;
            setProjects(updatedTextBoxes);
        });
      }

    const validateProject = (e) => {
        let validURL = true;
        const index = e.target.id.slice(-1)
        const value = e.target.value
        if (!value || !projects[index]?.thumbnail) {
          validURL = false;
        } else {
          validURL = true;
        }
        const updatedTextBoxes = [...projects];
        updatedTextBoxes[index].validURL = validURL;
        setProjects(updatedTextBoxes);
    };

    const handleKeyDown = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
          event.stopPropagation();
        }
      };

    if (!isCollectionModal || currentUser==null || collection==null) return null;

    return (        
        <Modal open={modal?.name=='collection'} onClose={handleClose} aria-labelledby="modal-collection">    
        <Stack sx={style}  >
            <Stack direction='row' spacing={2} justifyContent="space-between" alignItems="center" >
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontWeight:'800'}}>
                    {isNewCollection ? 'Create New Collection' : 'Collection' }
                </Typography>
                <Stack alignItems='baseline'>
                    <ActionButtonSecondary variant="contained" onClick={handleShareCollection}>Share This Collection</ActionButtonSecondary>
                </Stack>
            </Stack>
            <Stack direction='row' spacing={2} justifyContent="space-between" sx={{ marginTop: '12px' }}>

            { (modal.userImage || modal.userMaterial || modal.product?.src)  &&   <img src={modal.userImage?.src || modal.userMaterial?.src || modal.product?.src}  style={{marginTop:'20px',maxHeight:'64px', borderRadius:'10px'}} />}
            <Stack style={{width:'100%'}}  >
                <TextField {...textFieldProps} id="name"  label="Collection Name" name="name" value={formik.values.name} onChange={formik.handleChange} />
                {errors.name && <Typography variant="caption" color="red">Collection name must be more than 3 characters</Typography> }                
                <TextField {...textFieldProps} id="description"  label="Collection Description" name="description" multiline rows={2} value={formik.values.description} onChange={formik.handleChange} />
                {errors.description && <Typography variant="caption" color="red">Description must be less than 300 characters</Typography> }

                <Stack style={{ paddingBottom:'10px'}} >
                    <Typography color="#333" fontSize="16px" fontWeight="600">
                        Associated Project
                    </Typography>
                    <Typography color="#828282" fontSize="12px" fontWeight="500">
                       This project will serve as a template for the collection
                    </Typography>
                    <table className="projects-table" style={{ width: '100%'}}>
                    <thead style={{visibility: 'hidden'}}>
                      <tr>
                        <th style={{width: "70%"}}></th>
                        <th style={{width: "20%"}}></th>
                        <th style={{width: "10%"}}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects?.length > 0 && projects.map((project, index) => (
                        <tr key={'project'+ index} style={{padding: '0px !important'}}>
                          <td>
                              <TextField
                                inputProps={{
                                    style: {
                                      padding: "10px" 
                                    }
                                }}
                                fullWidth
                                variant="outlined"
                                type= {"url"}
                                id={'id'+ index}
                                data-testid="ProjectUrlForCollection"
                                value={project.url}
                                onKeyDown={handleKeyDown}
                                onChange={handleTextBoxChange}
                                onMouseOut={handleTextBoxBlur}
                                onBlur={validateProject}
                                error={!project?.validURL || project?.owner == false}
                                helperText={!project?.validURL ? 'Invalid Project Url!' : ((project?.owner == false) ? 'You can only add your own projects' : ' ' )}
                              />
                          </td>
                          <td style={{ verticalAlign: 'baseline', textAlign: 'center' }}>
                            {project.isLoading && !project?.thumbnail && project?.validURL && <CircularProgress />}
                            {project?.thumbnail && <img data-testid="ProjectThumbnailForCollection" src={cloudflareCDN(getUrlFromRef(project.thumbnail),'height=40,format=auto')} loading="lazy" alt="" />}
                          </td>
                          <td data-testid="RemoveProjectFromCollection" style={{cursor: 'pointer', verticalAlign: 'top', textAlign: 'center', paddingTop: '10px'}}
                            onClick={() => removeTextBox(index)}
                          >
                            <DeleteIcon sx={{color: '#757575'}} />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          {projects?.length < 1 &&
                            <ActionButton variant="contained" data-testid="AddProjectToCollection" onClick={addTextBox}>Add Project</ActionButton>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Stack>

                <ProfileUpload disabled={saving} maxResizeWidth={500} onFileChange={handleCoverImageUpload} img={collection.coverImage} title='Cover Image' defaultText=' ' deleteOption={true} onDelete={handleCoverImageDelete}/>
                <Divider style={{paddingTop:'20px'}} />

                <Stack style={{paddingTop:'20px'}} spacing={2} direction={'row'} justifyContent="space-between">
                    <ActionButton variant="contained" onClick={()=> handleClose()} disabled={saving} >Cancel</ActionButton>
                    <ActionButton variant="contained" onClick={handleSave} disabled={saving} >{saving ? 'Saving...' : 'Save Collection'}</ActionButton>
                </Stack>
            </Stack>
            </Stack>
        </Stack>
        </Modal>
    )
}

export const createNewCollection = (currentUserUid) => {
    if (!currentUserUid) throw new Error("currentUserUid is required")
    const data:CollectionInterface = {
        id: generateID(),
        name: '',
        uid: currentUserUid,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        protectedCollection: false,
        products: [],
        description: "",
        coverImage: "",
        userImages: [],
        userMaterials: [],
        thumbnails:[]
    };
    return data;
}
