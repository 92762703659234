import React, { useState,useContext,useEffect } from 'react'
import { AuthContext } from "../auth/Auth";
import { Snackbar, Tooltip,IconButton, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {useMattoState,useProjectData,usePhysicalObjects} from '../MattoState'
import {takeScreenshot} from '../libs/screenshot'
import {uploadAndSave} from '../libs/firebase'
import {generateID, setProfileFirstAction} from '../libs/util'
import useEventListener from '../libs/useEventListener';
import {BarIconButton} from './ButtonStyles'
import { useLoginState } from "../studioxr/auth/LoginState";
import { produce } from 'immer';
import { ProfileFirstActions } from 'ts-interfaces';
import * as Sentry from "@sentry/react";

export const SaveButton =  (props) => {

	const {currentUser} = useContext(AuthContext);
	const [openSnack, setOpenSnack]=useState(false)
	const projectData = useProjectData()
	const setProjectData = useProjectData(state=>state.set)
    const updateProjectData = useProjectData(state=>state.update)
	const physicalObjects = usePhysicalObjects( (state) => state.physicalObjects)
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const [message,setMessage]=useState("")
	const saveProjectNow = useMattoState((state) => state.saveProjectNow);
	const setShowLogin = useLoginState((state) => state.setShowLogin);

	const [isDirty, setIsDirty]=useState(false)
	const setStateDirty = useMattoState((state) => state.setStateDirty)
	const controls = useMattoState((state) => state.controls)

	useEventListener('beforeunload', (e) => {
		const dirty = useMattoState.getState().isStateDirty
		if (dirty==true && physicalObjects?.length > 1) {
			e.returnValue='Are you sure you want to leave?'
		}
	},window)

	const handleClick = () => { 
		if (openSnack==true || saveProjectNow==true) { console.log('Already saving'); return; }
		if (!currentUser?.uid) { setShowLogin('signup'); return; }
		setSelectedPhysicalObjectKey(null)		
		setOpenSnack(true) 
		setMessage('Saving...')
		useMattoState.setState({ saveProjectNow:true })
	}
	useEffect(() => { 
		const dirty = useMattoState.getState().isStateDirty
		if (!dirty && physicalObjects?.length > 0) { setStateDirty(true); }
	},[physicalObjects,projectData])

	useEffect(() => {
		if (saveProjectNow==true) handleSave()		
	}, [saveProjectNow])

	const handleSave = () => {
		if (useMattoState.getState().saveProjectNow!=true) { console.log("Not ready to save!"); return; }
		try {
			if (!currentUser?.uid) { setShowLogin('signup'); return; }
			setProfileFirstAction(ProfileFirstActions.SAVE_BUTTON).finally(() => {})
			let data = buildProject()
			let newProjectCreated = false;
			console.log("build ", data);
			if (projectData.projectId == null) {
				newProjectCreated = true;
				if (window.gtag)  window.gtag('event','new_project_created');
			}
			setOpenSnack(true) 
			setMessage('Saving Project..')
			const blob = takeScreenshot(1,0.5,true)		
			blob.extension = '.jpg'
			uploadAndSave(data.id,'projects',currentUser.uid, data, {screenshot:blob}).then((resp) => {
				console.log("Project Saved ", data.id);
				if(newProjectCreated) {
					const x = window.location.href.replace(/new.*/,data.id);
					window.history.pushState('', '', x);
					}
				if (window.gtag)  window.gtag('event','save_project') 
				setIsDirty(false)
				setStateDirty(false)
				setOpenSnack(true) 
				setTimeout(() => { useMattoState.setState({ saveProjectNow:false}) }, 900)			
				setMessage('Project Saved.')
			}).catch( (e) => {
				setTimeout(() => { useMattoState.setState({ saveProjectNow:false}) }, 900)			
				console.log("Project Failed.  Error is ", e,data.id);
				setOpenSnack(true) 
				const err = {
					message:e.message,
					data: JSON.stringify(data),		
					blob: JSON.stringify({ extension: blob.extension, size: blob.size, type: blob.type})
				}
				Sentry.captureException("Error saving project", e?.message)
				setMessage('Error Saving Project! ')			
			})
		}
		catch (e) {
			console.log("Error saving project",e)
			setTimeout(() => { useMattoState.setState({ saveProjectNow:false}) }, 900)			
		}

	}

	const handleKeyDown = (e) =>  { if (e.key=='s' && (e.metaKey==true || e.altKey==true)) 
			{ e.preventDefault();  handleClick(); }}
	useEventListener('keydown', handleKeyDown);
	
	const buildProject = () => {
		if (!currentUser?.uid) return;	
		const pObjects = JSON.parse(JSON.stringify(physicalObjects));
		const projectId = projectData.projectId ?? generateID()
		const cameraMatrix = window.camera.matrix.toArray()
		const userId = projectData.userId ?? currentUser.uid
		
		let controlsTarget = [0,0,0]
		if (controls.current?.target?.x) {
			controlsTarget = [controls.current.target.x,controls.current.target.y,controls.current.target.z]
		}
		setProjectData(state=> { 
			state.projectId = projectId
			state.cameraMatrix = cameraMatrix
			state.userId=userId
			state.controlsTarget = controlsTarget
		})

		//this fixes a bug when a background texture is not set - sometimes triggers undefined values which firebase dislikes.
		const backgroundTexture = produce(projectData.backgroundTexture, draft=> {
			if (draft?.materialData) {
				if  (draft?.materialData?.files==undefined) draft.materialData.files =null;
				if  (draft?.materialData?.name==undefined) draft.materialData.name =null;
				if  (draft?.materialData?.id==undefined) draft.materialData.id=null;
			}
		})
		return {
			id:projectId,
			user:currentUser.uid,
			updatedAt:Date.now(),
			projectData: {title:projectData.title, userId: currentUser.uid, envmap:projectData.envmap,projectId:projectId,
				scene:projectData.scene, cameraMatrix:cameraMatrix},
			physicalObjects:pObjects,
			lighting:projectData.lighting,
			backgroundTexture:backgroundTexture,
			controlsTarget:controlsTarget
		}
	}

	const addScreenshotToProjectData = (screenshot) => {
		updateProjectData({screenshot: screenshot})            
	}

	return (
		<React.Fragment>
		<Tooltip title="Save Project" arrow>
		<BarIconButton
			onClick={handleClick}
			edge='start'
			size="large"
			disabled={saveProjectNow===true ? true: false}>
			{/* disabled={isUploading || !props.isOnline ? true: false}> */}
			{/* <SaveIcon style={isUploading || !props.isOnline ? {color:'grey'}: {color:'black'}} /> */}
			<SaveIcon  style={saveProjectNow===true ? {color:'grey'}: {color:'black'}} />
		</BarIconButton>	
		</Tooltip>
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}}
			open={openSnack}
			onClose={ () => { setOpenSnack(false)}}
			autoHideDuration={5000}
			message={message}
		/>
		</React.Fragment>
	);	
}